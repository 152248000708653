
import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField   } from '@mui/material';
import {ECalApplicationFormBodyHead,PatientAccordion,FormGridItemGrey,FormGridItem,FamilyAccordion,
        convFloat,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import {useSelector, useDispatch, } from 'react-redux'
import { setState} from '../store/eCalcStore.js';

function ECalApplicationFormBodyIncome(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    let elderlyCount = props.ecalcState.elderlyCount;
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);
        let householdIncomeAssets2 = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});
        householdIncomeTotal =0;
        //(householdIncomeAssets2);
        dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
        if(allReset){ props.onReset();}
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]
    
    //console.log(props.ecalcState)
    
    return(
      <>
      <Box   sx={{ p:1,mx:2,my:1,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHead onChange={props.onChange} stepVal={props.stepVal}  enabledStep={props.enabledStep}/>
      
      <Stack borderBottom={1} sx={{ ml:1 }}>
        <Typography variant="h7" > <br/></Typography>
        <Typography variant="h7"  color="grey.700" > {props.i18n.t("tab2_header")} ${householdIncomeTotal}</Typography>
        <Typography variant="h7"  color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
        <Typography variant="h7"  color="grey.700" > {props.i18n.t("tab2_elderly_count")}:{elderlyCount}</Typography> 
      </Stack> 
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button>
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, initial */ 
            setDiagContent(props.i18n.t("msg_confirm_clear_all"));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(true);
            setOpen2(true);
        }}>{props.i18n.t("btnClearAll")}</Button>
      </Stack> 
      <Typography variant="h7" >  {props.i18n.t("tab2_note")} <IconButton onClick={()=>{handleClickOpen("tab2_note_tooltip_title","tab2_note_tooltip_desc")}} ><img src="images/question_mark.png"/></IconButton><br/></Typography>
      <Typography variant="h7" paragraph > {props.i18n.t("input_integer_only")}</Typography>
      <PatientAccordion keyid={"fa0"} FamilyIdx={0}  householdincomeArry={householdIncomeAssets}  defaultExpanded={ householdIncomeAssets.length-1 <1} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
        onChange={(ev)=>{ 
        //console.log(ev);
        let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,0,ev.item,ev.item==='isOver65'?ev.val:convFloat(ev.val));      
        let _subtotal =       householdIncomeAssets2[0].salary+
                              householdIncomeAssets2[0].allowance+
                              householdIncomeAssets2[0].double_pay+
                              householdIncomeAssets2[0].benefit_in_kind+
                              householdIncomeAssets2[0].pension+
                              householdIncomeAssets2[0].rental_income+
                              householdIncomeAssets2[0].sponsor+
                              householdIncomeAssets2[0].other_income;
        householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,0,"subTotal",_subtotal);
        elderlyCount =0; 
        householdIncomeTotal=0;     
        householdIncomeAssets2.forEach((record)=>{
          if(record.isOver65 === "true") elderlyCount++;        
          householdIncomeTotal +=record.subTotal;
        });
        //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
        dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
        }}  i18n={props.i18n}/>
      <>{
      
      }</>  
      {householdIncomeAssets.map((income)=>{
        //console.log(income);
        if(income.id>0)
          return (<FamilyAccordion key={"fa"+income.id} relationStr={relationStr} defaultExpanded={income.id==householdIncomeAssets.length-1} FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} 
          onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}}
          onRemove={(id)=>{
                            let householdIncomeAssets2 = removeHouseholdincomeItem(householdIncomeAssets,id);
                            elderlyCount =0;
                            householdIncomeTotal=0;
                            householdIncomeAssets2.forEach((record)=>{
                              if(record.isOver65 === "true") elderlyCount++;
                              householdIncomeTotal +=record.subTotal;
                            });
                            householdMemberCount =householdIncomeAssets2.length;
                            dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount})); 
                          }} 
          onChange={(ev)=>{                      
                              //console.log(ev);                              
                              let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,income.id,ev.item,(ev.item==='isOver65'||ev.item==='patient_rel')?ev.val:convFloat(ev.val));
                              //console.log(householdIncomeAssets2); 
                              let _subtotal = 
                              householdIncomeAssets2[income.id].salary+
                              householdIncomeAssets2[income.id].allowance+
                              householdIncomeAssets2[income.id].double_pay+
                              householdIncomeAssets2[income.id].benefit_in_kind+
                              householdIncomeAssets2[income.id].pension+
                              householdIncomeAssets2[income.id].rental_income+
                              householdIncomeAssets2[income.id].sponsor+
                              householdIncomeAssets2[income.id].other_income
  
                              householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,income.id,"subTotal",_subtotal);
                              elderlyCount =0;
                              householdIncomeTotal=0;
                              householdIncomeAssets2.forEach((record)=>{
                                if(record.isOver65 === "true") elderlyCount++;                              
                                householdIncomeTotal +=record.subTotal;
                              });
                              //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
                              dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));}}  
                              i18n={props.i18n}/>)
      })}
      
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
        <TextField value={householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:0.5,mr:2, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem"}}}/>
      </Stack>
  
      <Button  variant="text" startIcon={<img src="/images/blue_cross.png"/>} sx={{fontSize: 20,color: '#009688'}} onClick={()=>{   
          let householdIncomeAssets2 = addHouseholdincomeItemExist(householdIncomeAssets, {    
                                                                          patient_rel:'',
                                                                          salary:0,
                                                                          allowance:0,
                                                                          double_pay:0,
                                                                          benefit_in_kind:0,
                                                                          pension:0,
                                                                          rental_income:0,
                                                                          sponsor:0,
                                                                          other_income:0,
                                                                          isOver65:false,
                                                                          subTotal:0,
                                                                          cash:0,
                                                                          saving:0,
                                                                          investment:0,
                                                                          insurance:0,
                                                                          property:0,
                                                                          carpark:0,
                                                                          land:0,
                                                                          other:0,
                                                                          subTotal2:0,
                                                                        });
            householdMemberCount =householdIncomeAssets2.length;
          dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));                                                                      
      }} > {props.i18n.t("tab2_btnAddMember")} </Button>  
  
      <Grid container spacing={2} >
        <Grid item xs={4} >
          <Button variant="outlined" onClick={()=>{props.onChange("info")}} sx={{backgroundColor:'grey.200',}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} ></Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("asset");props.onNext();}} sx={{backgroundColor:'grey.200',}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalApplicationFormBodyIncome}