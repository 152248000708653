import {throttle} from 'lodash';
import { createSlice,configureStore } from '@reduxjs/toolkit';
const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
  };

  
  const eCalcStateSlice = createSlice({
    name: 'eCalcState',
    initialState: {
      config:{lang:'tc'},
      ecalc:{

      }
    },
    reducers: {
        setState: (state,action) => {
            state.ecalc = action.payload;
          }, 
        setLang: (state,action) => {
            state.config.lang = action.payload;
          }, 
    }
    })
    let prvstate = loadState();
    const store = configureStore({
      reducer: eCalcStateSlice.reducer,
      preloadedState:prvstate
    })    
    store.subscribe(throttle(() => {
        saveState({
            config:{lang:store.getState().config.lang},
            ecalc: store.getState().ecalc
        });
      },1000));    

      const {setState,setLang} = eCalcStateSlice.actions;
  export {loadState,saveState,store, eCalcStateSlice, 
    setState, setLang,
};