import * as React from 'react';
import {Stack,Typography, Grid,Box, Button,Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
    IconButton,TextField ,Skeleton      } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import {ECalApplicationFormBodyHeadDesktop} from './ECalApplicationFormCommon.js';
import {useSelector,} from 'react-redux';
import {useEffectOnce} from '../common/common-lib.js';

const APIHost = window.APIHost;

function ECalApplicationFormBodyResult(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let elderlyCount = props.ecalcState.elderlyCount;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
  
    const [incomeCatgSys,setIncomeCatgSys] = React.useState('01_b_50');
    const [assetCatgSys,setAssetCatgSys] = React.useState('01_b_lmt');
    const lang = useSelector((state)=> state.config.lang);

    const [reviewResult,setReviewResult] =React.useState(props.i18n.t("tab5_eligible"));
    const [review_income,setReview_income] = React.useState(props.i18n.t("tab5_income_01_b_50"));
    const [review_asset,setReview_asset] = React.useState( props.i18n.t("tab5_asset_01_b_lmt"));
    const [reviewResultTip,setReviewResultTip] = React.useState(false);

    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab5_noneligible_tooltip_title"));    
    const [diagContent, setDiagContent] = React.useState( props.i18n.t("tab5_noneligible_tooltip_desc") );
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

    const [showLoading,setShowLoading] = React.useState(false);
    useEffectOnce(()=>{
        //system assetment
        setShowLoading(true);
        axios({
            method: 'post',
            url: APIHost+'/systemassessment',
            data: {
                "hhSize": householdMemberCount,
                "elderlyCnt": elderlyCount,
                "incomeAmt": householdIncomeTotal,
                "assetAmt": householdAssetTotal,
                "assessmentDte":moment().format("YYYY-MM-DD HH:MM:SS")            
            },
            
          }).then((resp)=>{
            console.log(resp.data);
            
            setShowLoading(false);
            setIncomeCatgSys(resp.data.result.incomeCatgSys); 
            setAssetCatgSys(resp.data.result.assetCatgSys);

            if(resp.data.result.incomeCatgSys === '01_b_50'){
                setReview_income(props.i18n.t("tab5_income_01_b_50") );
            }else if(resp.data.result.incomeCatgSys === '02_50t75'){
                setReview_income(props.i18n.t("tab5_income_02_50t75") );
            }else if(resp.data.result.incomeCatgSys === '03_o_75'){
                setReview_income(props.i18n.t("tab5_income_03_o_75") );
            };
            if (resp.data.result.assetCatgSys == '01_b_lmt') {
                setReview_asset(props.i18n.t("tab5_asset_01_b_lmt"))
            } else if (resp.data.result.assetCatgSys == '02_o_lmt') {
                setReview_asset(props.i18n.t("tab5_asset_02_o_lmt"))
            }
            setReviewResultTip(false);
            if ((resp.data.result.incomeCatgSys == '01_b_50') && (resp.data.result.assetCatgSys == '01_b_lmt')) {
                setReviewResult( props.i18n.t("tab5_eligible") );
            } else if ((resp.data.result.incomeCatgSys == '02_50t75') && (resp.data.result.assetCatgSys == '01_b_lmt')) {
                setReviewResult( props.i18n.t("tab5_partial_eligible") );
            } else {
                setReviewResult(props.i18n.t("tab5_noneligible"));
                setReviewResultTip(true);
            }
            //console.log(props.refno);
            axios({
                method: 'post',
                url: APIHost+'/details',
                data: {
                    "refNo":props.refno,
                    "general" : {
                        "hhSize":householdMemberCount,
                        "elderlyCnt":elderlyCount
                        },
                    "hhmIncome" : householdIncomeAssets.map((item)=>{return {itemNo:item.id,patientRel:item.patient_rel,
                        salary:item.salary,allowance:item.allowance,doublePay:item.double_pay,benefitInKind:item.benefit_in_kind,
                        pension:item.pension,rentalIncome:item.rental_income,sponsor:item.sponsor,otherIncome:item.other_income} }),
                    "asset" : householdIncomeAssets.map((item)=>{return {itemNo:item.id,patientRel:item.patient_rel,
                        cash:item.cash,bankSaving:item.saving,investment:item.investment,insurance:item.insurance,
                        nonOwnerProperty:item.property,carpark:item.carpark,land:item.land,otherAssets:item.other
                        }}),
                    "assessment" : {
                        "incomeAmt":householdIncomeTotal,
                        "assetAmt":householdAssetTotal,
                        "assessmentDate":moment().format("YYYY-MM-DD")  ,
                        "mmdhiAmt":resp.data.result.mmdhiAmt,
                        "mmdhi50Amt":resp.data.result.mmdhi50Amt,
                        "mmdhi75Amt":resp.data.result.mmdhi75Amt,
                        "assetLimitAmt":resp.data.result.assetLimitAmt,
                        "incomeCatgSys":resp.data.result.incomeCatgSys,
                        "assetCatgSys":resp.data.result.assetCatgSys
                        }              
                },
                
              }).then((resp)=>{
                //console.log(resp.data);
                
              }).catch((err)=>{
                console.log(err);
              });
          }).catch((err)=>{
            console.log(err);
            //setShowLoading(false);            
            setDiagTitle("Connection error");            
            setDiagContent(err.toString());
            setOpen(true);
          })
          
    });
    React.useEffect(()=>{
        console.log("incomeCatgSys:"+incomeCatgSys);
        console.log("assetCatgSys:"+assetCatgSys);
        if(incomeCatgSys === '01_b_50'){
            setReview_income(props.i18n.t("tab5_income_01_b_50") );
        }else if(incomeCatgSys === '02_50t75'){
            setReview_income(props.i18n.t("tab5_income_02_50t75") );
        }else if(incomeCatgSys === '03_o_75'){
            setReview_income(props.i18n.t("tab5_income_03_o_75") );
        }else{
            setShowLoading(true);
        };
        if (assetCatgSys == '01_b_lmt') {
            setReview_asset(props.i18n.t("tab5_asset_01_b_lmt"))
        } else if (assetCatgSys == '02_o_lmt') {
            setReview_asset(props.i18n.t("tab5_asset_02_o_lmt"))
        }else{
            setShowLoading(true);
        };
        setReviewResultTip(false);
        if ((incomeCatgSys == '01_b_50') && (assetCatgSys == '01_b_lmt')) {
            setReviewResult( props.i18n.t("tab5_eligible") );
        } else if ((incomeCatgSys == '02_50t75') && (assetCatgSys == '01_b_lmt')) {
            setReviewResult( props.i18n.t("tab5_partial_eligible") );
        } else {
            setReviewResult(props.i18n.t("tab5_noneligible"));
            setReviewResultTip(true);
        }
    },[lang]);
    return(
        <>
            <Box sx={{ p:2,m:2,border:1, borderRadius:1 }} >
            <ECalApplicationFormBodyHeadDesktop onChange={props.onChange}  ecalcState={props.ecalcState} stepVal={props.stepVal} enabledStep={props.enabledStep}  i18n={props.i18n}/>
            
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
                <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{ 
                      const relationStr =[                        
                        { value:"Patient" , text: props.i18n.t("tab2_patient") } ,
                        { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
                        { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
                        { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
                        { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
                        { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
                      ]
                    let tmpincomeAssets=householdIncomeAssets.map((item)=>{
                        //console.log(item.patient_rel)
                        //console.log(relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text));
                        return {                                    
                                "id": item.id,
                                "patient_rel": relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text)[0],
                                "salary": item.salary.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "allowance": item.allowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "double_pay": item.double_pay.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "benefit_in_kind": item.benefit_in_kind.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "pension": item.pension.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "rental_income": item.rental_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "sponsor": item.sponsor.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "other_income": item.other_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "isOver65": item.isOver65,
                                "subTotal": item.subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "cash": item.cash.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "saving": item.saving.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "investment": item.investment.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "insurance": item.insurance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "property": item.property.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "carpark": item.carpark.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "land": item.land.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "other": item.other.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                "subTotal2": item.subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})                                    
                        }});
                    console.log({
                        "hhSize": householdMemberCount,
                        "elderlyCnt": elderlyCount,
                        "householdAssetTotal":householdAssetTotal,
                        "householdIncomeTotal":householdIncomeTotal,
                        "householdIncomeAssets":tmpincomeAssets,
                        "assessmentDate":moment().format("YYYY-MM-DD") ,
                        "lang":lang,
                        "reviewResult":reviewResult,
                        "review_income":review_income,
                        "review_asset":review_asset,
                    });
                    /*open preview*/                   
                    axios({
                        method: 'post',
                        url: APIHost+'/printPreview',
                        data: {
                            "hhSize": householdMemberCount,
                            "elderlyCnt": elderlyCount,
                            "householdAssetTotal":householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "householdIncomeTotal":householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                            "householdIncomeAssets":tmpincomeAssets,
                            "assessmentDate":moment().format("YYYY-MM-DD") ,
                            "lang":lang,
                            "reviewResult":reviewResult,
                            "review_income":review_income,
                            "review_asset":review_asset,
                        },
                        responseType: 'blob',
                      }).then((resp)=>{
                        //console.log(resp.data);
                        
                        var blob = new Blob([resp.data],{ type: 'application/pdf' });
                        var url = URL.createObjectURL(blob);
                        window.open(url, '__blank');
                       });                
                    }} >  {props.i18n.t("btnPreview")}</Button>
            </Stack> 
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > <br/> </Typography>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > {props.i18n.t("tab5_note") } </Typography>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > : </Typography>
            <Typography variant="h5"  sx={{fontWeight: 'bold'}}  display="inline" > {showLoading?<Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />:reviewResult}{reviewResultTip&&!showLoading?<IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_noneligible_tooltip_title","tab5_noneligible_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton>:<></>} </Typography>    
            <Typography variant="h5"  sx={{fontWeight: 'bold'}} ><br/></Typography>
            <Typography variant="h5"  sx={{fontWeight: 'bold'}} >{props.i18n.t("tab5_assessment_result_as_below")} :</Typography>
            <Typography variant="h5"  sx={{fontWeight: 'bold'}} ><br/></Typography>
            <Grid container alignItems="flex-end">
                <Grid item  xs={2} > <Typography variant="h5">{props.i18n.t("tab5_householdincome")}</Typography></Grid>
                <Grid item  xs={4}> 
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5"  >$</Typography>
                        <TextField value={householdIncomeTotal.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0})}
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.5,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={6} alignItems="flex-end"  ><Typography variant="h5" color='purple' >{showLoading?<Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />:review_income}</Typography></Grid>
            </Grid>
            <Grid container alignItems="center">    
                <Grid item  xs={2}>  <Typography variant="h5"  >{props.i18n.t("tab5_asset")}</Typography></Grid>
                <Grid item  xs={4}> 
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5"  >$</Typography>
                        <TextField value={householdAssetTotal.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.5,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={6}><Typography variant="h5" color='purple' >{showLoading?<Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />:review_asset}</Typography></Grid>
                <Grid item  xs={12}><Typography variant="h3" color='purple' >&nbsp;</Typography></Grid>
            </Grid>
            <Box >
                <Box  sx={{border: 2,backgroundColor: 'grey.100'}}>
                <Grid container  spacing={2}> 
                    <Grid item xs={12} justifyContent="flex-end" sx={{ my:4}} > <Typography  variant="h4" align="center" >{props.i18n.t("tab5_disclaimer_title")}</Typography></Grid> 
                    <Grid item xs={12} sx={{ mx:1}} > 
                        
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h5" align="left">{props.i18n.t("tab5_disclaimer_li1")}</Typography></Stack>          
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h5" align="left">{props.i18n.t("tab5_disclaimer_li2")}</Typography></Stack>
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h5" align="left">{props.i18n.t("tab5_disclaimer_li3")}</Typography></Stack>
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h5" align="left">{props.i18n.t("tab5_disclaimer_li4")}</Typography></Stack>
                        
                    </Grid>                    
                    <Grid item xs={12} sx={{ m:1}}  > </Grid>
                </Grid>
                </Box>
            </Box> 
            <Grid container spacing={2} >
                <Grid item xs={12} />
                <Grid item xs={3} >
                    <Button variant="outlined" onClick={()=>{props.onChange("asset")}} sx={{fontSize: 22}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
                </Grid>
                <Grid item xs={9} ></Grid>
            </Grid>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
    
  }
  export {ECalApplicationFormBodyResult as ECalApplicationFormBodyResultDesktop}