import * as React from 'react';
import {Snackbar ,Alert, Grid,Typography } from '@mui/material';
import {useSelector, useDispatch, Provider} from 'react-redux';
import { setState,store} from './store/eCalcStore.js';
import './App.css';
import { createTheme, ThemeProvider  } from '@mui/material/styles';

import {ECalLandingFooter,ECalLandingHeader,ECalLanding} from './view/ECalLanding.js';
import {ECalLandingFooterDesktop,ECalLandingHeaderDesktop,ECalLandingDesktop} from './view/ECalLandingDesktop.js';
import {ECalApplicationFormBodyInfo} from './view/ECalApplicationFormInfo.js';
import {ECalApplicationFormBodyInfoDesktop} from './view/ECalApplicationFormInfoDesktop.js';
import {ECalApplicationFormBodyIncome} from './view/ECalApplicationFormIncome.js';
import {ECalApplicationFormBodyIncomeDesktop} from './view/ECalApplicationFormIncomeDesktop.js';
import {ECalApplicationFormBodyAsset} from './view/ECalApplicationFormAsset.js';
import {ECalApplicationFormBodyAssetDesktop} from './view/ECalApplicationFormAssetDesktop.js';
import {ECalApplicationFormBodyResult} from './view/ECalApplicationFormResult.js';
import {ECalApplicationFormBodyResultDesktop} from './view/ECalApplicationFormResultDesktop.js';
import i18n from "i18n-js";
import en from "./translate/en.json";
import tc from "./translate/tc.json";
import sc from "./translate/sc.json";
import  en_desktop from "./translate/en_desktop.json";
import  tc_desktop from "./translate/tc_desktop.json";
import sc_desktop from "./translate/sc_desktop.json";
import {isMobile} from './common/common-lib.js';

i18n.fallbacks = true;
i18n.locale = 'tc';

i18n.translations = {  ...en, ...tc,...sc ,
  ...en_desktop,...tc_desktop,...sc_desktop
};


function ECalApplicationForm(props){
  const [stepVal,setStepVal] = React.useState("info");
  const [alertOpen,setAlertOpen] = React.useState(false);
  const [alertMsg,setAlertMsg] = React.useState("");

  const householdAssetTotal =useSelector((state)=>state.ecalc.householdAssetTotal);
   const householdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);
   const householdMemberCount =useSelector((state)=>state.ecalc.householdMemberCount);
   const elderlyCount =useSelector((state)=>state.ecalc.elderlyCount);
   const householdIncomeAssets =useSelector((state)=>state.ecalc.householdIncomeAssets);
   
  const checkinput = (prvState)=>{
    if(prvState ==="asset") {  
      if(householdAssetTotal ===0){
        
        setAlertMsg(props.i18n.t("msg_confirm_asset"));
        setAlertOpen(true);
      }   
    }
    if(prvState ==="income") {
        //validateRelationship
        let relquery = householdIncomeAssets.filter(item=>item.patient_rel==='').map(item=>{return {id:item.id,patient_rel:item.patient_rel}});
        //console.log(relquery);
        if(relquery.length>0){
          setAlertMsg(props.i18n.t("msg_missing_relation").replace('{0}',relquery[0].id));
          setAlertOpen(true);
          return false;
        }
        //console.log(householdIncomeTotal);
        if(householdIncomeTotal ===0){
          setAlertMsg(props.i18n.t("msg_confirm_houlseholdincome"));
          setAlertOpen(true);
        }
    }
    return true;
  }
  let ecalcState={householdAssetTotal,householdIncomeTotal,householdMemberCount,elderlyCount,householdIncomeAssets};
  function renderBody(){
    if(stepVal === "result"){
      return <ECalApplicationFormBodyResult refno={props.refno} ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/ >
    }
    if(stepVal === "asset"){
        return <ECalApplicationFormBodyAsset ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal))  setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} onNext={()=>{props.onNext(4)}} onReset={props.onReset} i18n={props.i18n}/>
    }
    if(stepVal === "income"){
        return <ECalApplicationFormBodyIncome ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(3)}} onReset={props.onReset} i18n={props.i18n}/> 
    }
    if(stepVal === "info"){
     return   <ECalApplicationFormBodyInfo ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(2)}} i18n={props.i18n}/> 
    }
    
  }
  return(
    <>
    <ECalLandingHeader i18n={i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}}/>
    {renderBody()}
    <Snackbar anchorOrigin={{ vertical:"top",horizontal:'center'}}open={alertOpen} autoHideDuration={6000} onClose={() => {setAlertOpen(false);}}>
      <Alert onClose={() => {setAlertOpen(false);}}severity="error">{alertMsg}</Alert>
    </Snackbar>
    <ECalLandingFooter i18n={props.i18n}/>
    </>
  )
}

function ECalApplicationFormDesktop(props){
  const [stepVal,setStepVal] = React.useState("info");
  const [alertOpen,setAlertOpen] = React.useState(false);
  const [alertMsg,setAlertMsg] = React.useState("");

  const householdAssetTotal =useSelector((state)=>state.ecalc.householdAssetTotal);
   const householdIncomeTotal =useSelector((state)=>state.ecalc.householdIncomeTotal);
   const householdMemberCount =useSelector((state)=>state.ecalc.householdMemberCount);
   const elderlyCount =useSelector((state)=>state.ecalc.elderlyCount);
   const householdIncomeAssets =useSelector((state)=>state.ecalc.householdIncomeAssets);
   
  const checkinput = (prvState)=>{
    if(prvState ==="asset") {  
      if(householdAssetTotal ===0){
        
        setAlertMsg(props.i18n.t("msg_confirm_asset"));
        setAlertOpen(true);
      }   
    }
    if(prvState ==="income") {
        //validateRelationship
        let relquery = householdIncomeAssets.filter(item=>item.patient_rel==='').map(item=>{return {id:item.id,patient_rel:item.patient_rel}});
        //console.log(relquery);
        if(relquery.length>0){
          setAlertMsg(props.i18n.t("msg_missing_relation").replace('{0}',relquery[0].id));
          setAlertOpen(true);
          return false;
        }
        //console.log(householdIncomeTotal);
        if(householdIncomeTotal ===0){
          setAlertMsg(props.i18n.t("msg_confirm_houlseholdincome"));
          setAlertOpen(true);
        }
    }
    return true;
  }
  let ecalcState={householdAssetTotal,householdIncomeTotal,householdMemberCount,elderlyCount,householdIncomeAssets};
  function renderBody(){
    if(stepVal === "result"){
      return <ECalApplicationFormBodyResultDesktop refno={props.refno} ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/ >
    }
    if(stepVal === "asset"){
        return <ECalApplicationFormBodyAssetDesktop ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal))  setStepVal(newVal)}} stepVal={stepVal} enabledStep={props.enabledStep} onNext={()=>{props.onNext(4)}} onReset={props.onReset} i18n={props.i18n}/>
    }
    if(stepVal === "income"){
        return <ECalApplicationFormBodyIncomeDesktop ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" "&&checkinput(stepVal)) setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(3)}} onReset={props.onReset} i18n={props.i18n}/> 
    }
    if(stepVal === "info"){
     return   <ECalApplicationFormBodyInfoDesktop ecalcState={ecalcState} onChange={(newVal)=>{if(newVal!==" ") setStepVal(newVal)}} stepVal={stepVal}  enabledStep={props.enabledStep} onNext={()=>{props.onNext(2)}} i18n={props.i18n}/> 
    }
    
  }
  return(
    <>
    <ECalLandingHeaderDesktop i18n={i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}}/>
    {renderBody()}
    <Snackbar anchorOrigin={{ vertical:"top",horizontal:'center'}}open={alertOpen} autoHideDuration={6000} onClose={() => {setAlertOpen(false);}}>
      <Alert onClose={() => {setAlertOpen(false);}} severity="error" ><Typography variant="h6" >{alertMsg}</Typography></Alert>
    </Snackbar>
    <ECalLandingFooterDesktop i18n={props.i18n}/>
    </>
  )
}

function AppBody(){
  const [refno,setRefno] = React.useState('');  
  const lang = useSelector((state)=> state.config.lang);
  i18n.locale =useSelector((state)=>state.config.lang);
  const [agreeChecked,setAgreeChecked] = React.useState(false);
  const [enabledStep,setEnabledStep] = React.useState(1);
  const theme = createTheme({
                              typography: {
                                // Tell MUI what the font-size on the html element is.
                                FontSize: 9,                               
                                button: {
                                  textTransform: 'none'
                                }, 
                              }, 
                                                          
                            });
   //input
   const householdAssetTotal =0;
   const householdIncomeTotal=0;
   const householdMemberCount = 1;
   const elderlyCount = 0;
   const householdIncomeAssets = [
     {
       id:0,
       patient_rel:'Patient',
      
       salary:0,
       allowance:0,
       double_pay:0,
       benefit_in_kind:0,
       pension:0,
       rental_income:0,
       sponsor:0,
       other_income:0,
       isOver65:false,
       subTotal:0,

       cash:0,
       saving:0,
       investment:0,
       insurance:0,
       property:0,
       carpark:0,
       land:0,
       other:0,
       subTotal2:0,
     }
   ];

   const dispatch = useDispatch()

  if(agreeChecked !== true){
    return (
        <ThemeProvider theme={theme}>
          <ECalLanding onAgree={(_refno)=>{
            setRefno(_refno);
            setAgreeChecked(true);
            setEnabledStep(1);
            dispatch(setState({householdIncomeTotal,householdAssetTotal,householdMemberCount,householdIncomeAssets,elderlyCount,refNo:_refno}));
          }}  i18n={i18n}/>    
        </ThemeProvider>
    )}else{
      return (
        <ThemeProvider theme={theme}>
          <ECalApplicationForm  lang={lang} refno={refno} enabledStep={enabledStep} onNext={(step)=>{setEnabledStep(step)}} onReset={()=>{setAgreeChecked(false); }} i18n={i18n}/>
        </ThemeProvider>
      )
    };
}

function AppBodyDesktop(){
  const langMap ={ en:'en_desktop',sc:'sc_desktop',tc:'tc_desktop',}
  const [refno,setRefno] = React.useState('');  
  const lang = useSelector((state)=> state.config.lang);
  console.log(langMap[useSelector((state)=>state.config.lang)]);
  i18n.locale =langMap[useSelector((state)=>state.config.lang)];
  const [agreeChecked,setAgreeChecked] = React.useState(false);
  const [enabledStep,setEnabledStep] = React.useState(1);
  const theme = createTheme({
    typography: {
       button: {
        textTransform: 'none'
      },                               
    },                             
  });

   //input
   const householdAssetTotal =0;
   const householdIncomeTotal=0;
   const householdMemberCount = 1;
   const elderlyCount = 0;
   const householdIncomeAssets = [
     {
       id:0,
       patient_rel:'Patient',
      
       salary:0,
       allowance:0,
       double_pay:0,
       benefit_in_kind:0,
       pension:0,
       rental_income:0,
       sponsor:0,
       other_income:0,
       isOver65:false,
       subTotal:0,

       cash:0,
       saving:0,
       investment:0,
       insurance:0,
       property:0,
       carpark:0,
       land:0,
       other:0,
       subTotal2:0,
     }
   ];

   const dispatch = useDispatch()

  if(agreeChecked !== true){
    return (
        <Grid container > 
            <Grid item xs={2} zeroMinWidth >
            </Grid>
          <Grid item xs={8}  >
            <ThemeProvider theme={theme}>
              <ECalLandingDesktop onAgree={(_refno)=>{
                setRefno(_refno);
                setAgreeChecked(true);
                setEnabledStep(1);
                dispatch(setState({householdIncomeTotal,householdAssetTotal,householdMemberCount,householdIncomeAssets,elderlyCount,refNo:_refno}));
              }}  i18n={i18n}/>    
            </ThemeProvider>
          </Grid>
          <Grid item xs={2} zeroMinWidth>
          </Grid>
        </Grid>
    )}else{
      return ( 
          <Grid container > 
          <Grid item xs={1} zeroMinWidth>
          </Grid>
          <Grid item xs={10}  >
            <ThemeProvider theme={theme}>
              <ECalApplicationFormDesktop  lang={lang} refno={refno} enabledStep={enabledStep} onNext={(step)=>{setEnabledStep(step)}} onReset={()=>{setAgreeChecked(false); }} i18n={i18n}/>
            </ThemeProvider>
          </Grid>
              
          <Grid item xs={1} zeroMinWidth>
          </Grid>
          </Grid>
        )
    };
}


function App() {  
  return(
    <Provider store={store}>
      {(isMobile())?<AppBody/>:<AppBodyDesktop/>}        
    </Provider>
  )
}

export default App;
