import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField   } from '@mui/material';
import { ECalApplicationFormBodyHead,PatientAssetAccordion,FormGridItemGrey,FormGridItem,FamilyAssetAccordion,
        convFloat,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import {useSelector, useDispatch, } from 'react-redux'
import { setState} from '../store/eCalcStore.js';

function ECalApplicationFormBodyAsset(props){
  
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    let elderlyCount = props.ecalcState.elderlyCount;
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
     
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);    
        let householdIncomeAssets2 = householdIncomeAssets.map((item) => {return { ...item, cash:0,saving:0,investment:0,insurance:0, property:0,carpark:0,land:0,other:0,subTotal2:0};});
        householdAssetTotal =0;
        //console.log(householdIncomeAssets2);
        dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
        if(allReset){ props.onReset();}
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      setDiagTitle(props.i18n.t(helpTipsTitleKey));
      setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab3_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab3_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]
    //React.useEffect(()=>{console.log(props.ecalcState)},[]);
  return (
    <>
    <Box   sx={{ p:1,mx:2,mt:1,border:1, borderRadius:1 }} >
    <ECalApplicationFormBodyHead onChange={props.onChange} stepVal={props.stepVal}  enabledStep={props.enabledStep} />
    <Stack borderBottom={1} sx={{ ml:1 }}>
      <Typography variant="h7" > <br/></Typography>
      <Typography variant="h7"  color="grey.700" > {props.i18n.t("tab3_header")} ${householdAssetTotal}</Typography>
      <Typography variant="h7"  color="grey.700" > {props.i18n.t("tab3_household_count")} {householdMemberCount}</Typography>
    </Stack> 
    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/reset_btn.gif"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/             
             setDiagContent( props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab3_header")));
             setDiagTitle(props.i18n.t("tab3_header"));
             setAllReset(false);
             setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button>
        <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/reset_btn.gif"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, initial */ 
            setDiagContent(props.i18n.t("msg_confirm_clear_all"));
            setDiagTitle(props.i18n.t("tab3_header"));
            setAllReset(true);
            setOpen2(true);
            }}>{props.i18n.t("btnClearAll")}</Button>
    </Stack> 
    <Typography variant="body" > {props.i18n.t("tab3_note")} <IconButton onClick={()=>{handleClickOpen("tab3_note_tooltip_title","tab3_note_tooltip_desc")}} ><img src="images/question_mark.png"/></IconButton></Typography>
    <Typography variant="body" paragraph > {props.i18n.t("input_integer_only")}</Typography>
    <PatientAssetAccordion  FamilyIdx={0} householdincomeArry={householdIncomeAssets}  onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{
      //console.log(helpTipsKey);
      handleClickOpen(helpTipsTitleKey,helpTipsKey);}} onChange={(ev)=>{
        //console.log(ev);
        let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,0,ev.item,(ev.item==='isOver65')?ev.val:convFloat(ev.val));      
        let _subtotal =  householdIncomeAssets2[0].cash+
                          householdIncomeAssets2[0].saving+
                          householdIncomeAssets2[0].investment+
                          householdIncomeAssets2[0].insurance+
                          householdIncomeAssets2[0].property+
                          householdIncomeAssets2[0].carpark+
                          householdIncomeAssets2[0].land+
                          householdIncomeAssets2[0].other;                          
        householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,0,"subTotal2",_subtotal);     
        householdAssetTotal=0;     
        householdIncomeAssets2.forEach((record)=>{              
        householdAssetTotal +=record.subTotal2;
       });
       //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
       dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
    }} i18n={props.i18n}/>
    {householdIncomeAssets.map((income)=>{
        //console.log(income);
        if(income.id>0)
          return (<FamilyAssetAccordion key={"faa"+income.id} relationStr={relationStr} FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} onRemove={(id)=>{
            let householdIncomeAssets2 = removeHouseholdincomeItem(householdIncomeAssets,id);
            householdAssetTotal=0;
            householdIncomeAssets2.forEach((record)=>{                        
              householdAssetTotal +=record.subTotal2;
            });
            
            dispatch(setState({householdIncomeTotal,householdAssetTotal,HouseholdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));                                                                      
          }} onChange={(ev)=>{
              let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,income.id,ev.item,(ev.item==='isOver65'||ev.item==='patient_rel')?ev.val:convFloat(ev.val));      
              let _subtotal = householdIncomeAssets2[income.id].cash+
                              householdIncomeAssets2[income.id].saving+
                              householdIncomeAssets2[income.id].investment+
                              householdIncomeAssets2[income.id].insurance+
                              householdIncomeAssets2[income.id].property+
                              householdIncomeAssets2[income.id].carpark+
                              householdIncomeAssets2[income.id].land+
                              householdIncomeAssets2[income.id].other;
                                    
              householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,income.id,"subTotal2",_subtotal);
              
              householdAssetTotal=0;     
              householdIncomeAssets2.forEach((record)=>{              
                householdAssetTotal +=record.subTotal2;
            });
            //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
            dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
          }} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{console.log(helpTipsKey);handleClickOpen(helpTipsTitleKey,helpTipsKey);}} i18n={props.i18n}/>)
      })}
        <Stack direction="row" alignItems="center"  justifyContent="flex-end" sx={{mt:1}}> 
          <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")}</Typography>     
          <TextField value={householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:0.5,mr:2, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem",backgroundColor:'grey.200'}}} />
        </Stack>
        <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={4} >
          <Button variant="outlined" onClick={()=>{props.onChange("income")}} sx={{backgroundColor:'grey.200',}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} ></Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("result");props.onNext();}} sx={{backgroundColor:'grey.200',}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>
    </Box>
    
     <Dialog
         open={open}
         onClose={handleClose}
         fullWidth
       >
         <DialogTitle >
           {diagTitle}
         </DialogTitle>
         <DialogContent>
           <DialogContentText >
             <div dangerouslySetInnerHTML={{__html:diagContent}}/>
           </DialogContentText>
         </DialogContent>
         <DialogActions>          
           <Button onClick={handleClose} autoFocus>             
             {props.i18n.t("tooltip_close")}             
           </Button>
         </DialogActions>
       </Dialog>
       <Dialog
          open={open2}
          onClose={handleClose2}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
     </>)
  
  }
  
  export {ECalApplicationFormBodyAsset}