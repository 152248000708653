import * as React from 'react';
import {Stack,Checkbox,Typography, Grid, Link ,Box, Button,Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import { setLang} from '../store/eCalcStore.js';
import {isMobile,useEffectOnce} from '../common/common-lib.js';
import querySearch from "stringquery";

//const APIHost = 'http://160.1.43.61:8080/ews-ecal-api-slt/ecalc';
const APIHost = window.APIHost//'http://localhost:3100';
var query = querySearch(window.location.search);

function ECalLandingHeader(props){
  const dispatch = useDispatch()
  const lang = useSelector((state)=> state.config.lang);
  const [mFontsize,setMFontsize ] = React.useState("medium");
  let scView =()=>{
    return (
      <>
              <Link href="#" onClick={()=>{dispatch(setLang("tc"))}} underline="none" color="grey.500" sx={{mx:1}}>
              繁體
              </Link> 
              <Link href="#" onClick={()=>{dispatch(setLang("en"))}} underline="none" color="grey.500">
              English
              </Link>
      </>
    )  
  }
  let tcView =()=>{
    return (
      <>      
              <Link href="#" onClick={()=>{dispatch(setLang("sc"))}} underline="none" color="grey.500" sx={{mx:1}}>
              简体 
              </Link>
              
              <Link href="#" onClick={()=>{dispatch(setLang("en"))}} underline="none" color="grey.500">
              English
              </Link>
      </>
    )  
  }
  let enView =()=>{
  return (
    <>
            <Link href="#" onClick={()=>{dispatch(setLang("tc"))}} underline="none" color="grey.500" sx={{mx:1}}>
            繁體
            </Link> 
            <Link href="#" onClick={()=>{dispatch(setLang("sc"))}} underline="none" color="grey.500">
            简体
            </Link>
    </>
  )  
  }
  let langSelect = ()=>{
    if(lang ==="en") { return enView() }
    if(lang ==="tc") { return tcView() }
    if(lang ==="sc") { return scView() }
  }
  useEffectOnce(()=>{      
    props.onFontChange("90%");
    
    if(query["lang"]==="tc"||query["lang"]==="sc"||query["lang"]==="en")
      dispatch(setLang(query["lang"] ))
    
  });
    return (<>
      <Grid container  spacing={2} sx={{p:1}} >
      <Grid item xs={3}>
        <Link href="#" onClick={()=>{ 
                        let url = props.i18n.t("header_ha_link")
                        window.open(url);
                      }} >
          <img src='/images/ha_logo.gif' width={window.innerWidth/4} />
        </Link>
      </Grid>
      <Grid item xs={4}  >
        
      </Grid>
      <Grid item xs={5}  >
        <Stack direction="row" justifyContent="center" spacing={1}>
        <Typography color="grey.500" variant="body2" noWrap > {props.i18n.t("font_size")} </Typography>
        <Link href="#" onClick={()=>{
          setMFontsize("small")
          props.onFontChange("80%");
          //document.getElementById("root").style.fontSize = "small";
          }} underline="none">
          {mFontsize==="small"?<img src='/images/btn_as_on.jpg' border='0' height='16px' width='16px'/> :<img src='/images/btn_as.jpg' border='0' height='16px' width='16px'/> }
        </Link>
        <Link href="#" onClick={()=>{
          setMFontsize("medium");
          props.onFontChange("90%");
          //document.getElementById("root").style.fontSize = "medium";
          }} underline="none">
          {mFontsize==="medium"?<img src='/images/btn_am_on.jpg' border='0' height='16px' width='16px'/>:<img src='/images/btn_am.jpg' border='0' height='16px' width='16px'/>}
        </Link>
        <Link href="#" onClick={()=>{
          setMFontsize("large");
          props.onFontChange("110%");
          //document.getElementById("root").style.fontSize = "large";
        }} underline="none">
          {mFontsize==="large"?<img src='/images/btn_al_on.jpg' border='0' height='16px' width='16px'/>:<img src='/images/btn_al.jpg' border='0' height='16px' width='16px'/>}
        </Link>
        </Stack>
        <Stack direction="row" justifyContent="right" spacing={0} sx={{mr:1.5}}>
          {langSelect()}
          </Stack>
      </Grid>
    </Grid>
    <Box  sx={{backgroundImage :'url(/images/blue_title.jpg)',mx:2,px:1}}><Typography variant="h5" color="grey.50"> {props.i18n.t("web_title")}</Typography></Box>
    </>
    );
  }
function ECalLandingBody(props){
    const [checked,setChecked]=React.useState(false);
    const [loading,setLoading]=React.useState(false);
    
  return(
    <Box sx={{mx:1,p:1 }} >
        <Box   sx={{ m:1,py:2,px:1,border: 1}}>
        <Grid container  > 
          <Grid item xs={12} > <Typography color="grey.800" variant="h6" align="center" > {props.i18n.t("disclaimer_title")}  </Typography></Grid> 
          <Grid item xs={12}  > <Box height={ (window.innerHeight/1.7)+'px'} sx={{ p:4, mb:4, overflow: "hidden",overflowY: "scroll" ,border:1,borderColor: 'grey.400' }} >
          
            <Stack direction="row" ><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}} >{props.i18n.t("disclaimer_li1")}</Typography></Stack>          
            <Stack direction="row" ><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}} >{props.i18n.t("disclaimer_li2")}</Typography></Stack>
            <Stack direction="row" ><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}} >{props.i18n.t("disclaimer_li3")}</Typography></Stack>
            <Stack direction="row" ><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}}>{props.i18n.t("disclaimer_li4")}</Typography></Stack>
            <Stack direction="row"><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}}component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("disclaimer_li5")}}/></Typography></Stack>            
            <Stack direction="row" ><li/><Typography   variant="body1" align="left" sx={{lineHeight:1,}} >{props.i18n.t("disclaimer_li6")}</Typography></Stack>
            <Stack direction="row" ><li/><Typography   variant="body1" align="left"  sx={{lineHeight:1,}}>{props.i18n.t("disclaimer_li7")}</Typography></Stack>
            <Stack direction="row" ><li/><Typography  paragraph variant="body1" align="left"  sx={{lineHeight:1,}}>{props.i18n.t("disclaimer_li8")}</Typography></Stack>
          </Box></Grid>
          <Grid item xs={12}   > 
            <Stack direction="row" alignItems="center" justifyContent="center"> <Checkbox onChange={(ev)=>{ setChecked(ev.target.checked)}}/><Typography  variant="body2" align="center" sx={{fontWeight: 'bold'}} >{props.i18n.t("disclaimer_checkbox")}</Typography></Stack>
          </Grid>
          <Grid item xs={12}  > 
            <Stack direction="row" gap={1} justifyContent="center" sx={{m:1}}><Button variant="contained" disabled={!checked} loading={{loading}} onClick={()=>{ setLoading(true);props.onAgree()}}> {props.i18n.t("disclaimer_button")} </Button></Stack>
          </Grid>
          <Grid item xs={12}   > </Grid>
        </Grid>
      </Box>
    </Box>
  )
  
  }
function ECalLanding(props){
  const [diagTitle, setDiagTitle] = React.useState( "");    
  const [diagContent, setDiagContent] = React.useState( "Cannot connect server" );
  const [open, setOpen] = React.useState(false);
  const lang = useSelector((state)=> state.config.lang);
  const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      setDiagTitle(props.i18n.t(helpTipsTitleKey));
      setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
  const handleClose = () => {
    setOpen(false);
  }; 
    return ( 
    <>
      <ECalLandingHeader i18n={props.i18n} onFontChange={(fontsize)=>{document.getElementById("root").style.zoom =fontsize}} />
      <ECalLandingBody onAgree={(ev)=>{
        let mlang = "zh-HK";
        if(lang === "en") 
          mlang = "en-US";
        if(lang === "sc") 
          mlang = "zh-CN";

        axios({
                method: 'post',
                url: APIHost+'/controltable',
                data: {
                  "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
                  "refNo":"",
                  "lang":mlang,    
                  "ipAddress":"",
                  "browser":"",
                  "isMobileDevice":isMobile(),
                  "userAgent":"",
                  ScreenWidth:window.innerWidth,
                  ScreenHeight:window.innerHeight,
                },
                
              }).then((resp)=>{
                console.log(resp.data);
                props.onAgree(resp.data.result.refNo);
              }).catch((err)=>{
                console.log(err);
                setDiagTitle("Connection error");            
                setDiagContent(err.toString());
                setOpen(true);
                //props.onAgree(0);
              })
      }} i18n={props.i18n}/>
      <ECalLandingFooter i18n={props.i18n}/>
      <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
    </>
    );
  }
function ECalLandingFooter(props){
    return(
      <>
      <Stack direction="row"  spacing={2}>
          <Typography component={'span'} variant="body2" color="grey.500" > <div dangerouslySetInnerHTML={{__html:props.i18n.t("footer_ha_copyright").replace('{0}',moment().format("YYYY"))}} />  </Typography>
          <Link href="#" onClick={()=>{ 
                let url = props.i18n.t("footer_allrightreserve_link")
                window.open(url, '__blank');
              }} >
            <Typography variant="body2" color="grey.500">{props.i18n.t("footer_allrightreserve")}</Typography>
          </Link>
        </Stack>
        <Stack direction="row"  spacing={2}>          
          <Link href="#" onClick={()=>{ 
                  let url = props.i18n.t("footer_privacy_link")
                  window.open(url, '__blank');
                }} >
            <Typography variant="body2" color="grey.500">{props.i18n.t("footer_privacy")}</Typography>
          </Link>
          <Link href="#" onClick={()=>{ 
                      let url = props.i18n.t("footer_linkpolicy_link")
                      window.open(url, '__blank');
                    }} >
            <Typography variant="body2" color="grey.500">{props.i18n.t("footer_linkpolicy")}</Typography> 
          </Link>
          <Link href="#" onClick={()=>{ 
                        let url = props.i18n.t("footer_disclaimer_link")
                        window.open(url, '__blank');
                      }} >
            <Typography variant="body2" color="grey.500">{props.i18n.t("footer_disclaimer")}</Typography> 
          </Link>
          <Link href="#" onClick={()=>{ 
                        let url = props.i18n.t("footer_termofuse_link")
                        window.open(url, '__blank');
                      }} >
            <Typography variant="body2" color="grey.500">{props.i18n.t("footer_termofuse")}</Typography>
          </Link>
      </Stack>
      
      </>
    )
  
  }
export {ECalLandingFooter,ECalLandingBody,ECalLandingHeader,ECalLanding}