import * as React from 'react';
import {Stack,Typography, Grid, Box, Button, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  } from '@mui/material';
import {ECalApplicationFormBodyHeadDesktop} from './ECalApplicationFormCommon.js';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector} from 'react-redux'
import { setState} from '../store/eCalcStore.js';
const APIHost = window.APIHost;

function ECalApplicationFormBodyInfoDesktop(props){

  let householdAssetTotal = props.ecalcState.householdAssetTotal;
  let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
  let householdMemberCount = props.ecalcState.householdMemberCount;
  let elderlyCount = props.ecalcState.elderlyCount;
  let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
  const [reviewResult,setReviewResult] =React.useState(props.i18n.t("tab5_eligible"));
  const [review_income,setReview_income] = React.useState(props.i18n.t("tab5_income_01_b_50"));
  const [review_asset,setReview_asset] = React.useState( props.i18n.t("tab5_asset_01_b_lmt"));
  const lang = useSelector((state)=> state.config.lang);
  const dispatch = useDispatch()

  const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
  const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [allReset,setAllReset] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOk2=()=>{
      setOpen2(false);
      let householdIncomeAssets2 = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});
      householdIncomeTotal =0;
      //(householdIncomeAssets2);
      dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
      if(allReset){ props.onReset();}
    }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      setDiagTitle(props.i18n.t(helpTipsTitleKey));
      setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return(
    <>
    <Box   sx={{ p:2,m:2,border:1, borderRadius:1 }} >
    <ECalApplicationFormBodyHeadDesktop onChange={props.onChange}  ecalcState={props.ecalcState} stepVal={props.stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/>
    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" sx={{ mt:2 }}>
              <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{ 
                              const relationStr =[                        
                                { value:"Patient" , text: props.i18n.t("tab2_patient") } ,
                                { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
                                { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
                                { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
                                { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
                                { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
                              ]
                            let tmpincomeAssets=householdIncomeAssets.map((item)=>{
                                //console.log(item.patient_rel)
                                //console.log(relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text));
                                return {                                    
                                        "id": item.id,
                                        "patient_rel": relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text)[0],
                                        "salary": item.salary.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "allowance": item.allowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "double_pay": item.double_pay.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "benefit_in_kind": item.benefit_in_kind.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "pension": item.pension.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "rental_income": item.rental_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "sponsor": item.sponsor.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "other_income": item.other_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "isOver65": item.isOver65,
                                        "subTotal": item.subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "cash": item.cash.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "saving": item.saving.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "investment": item.investment.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "insurance": item.insurance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "property": item.property.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "carpark": item.carpark.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "land": item.land.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "other": item.other.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "subTotal2": item.subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),                                    
                                }});
                            console.log({
                                "hhSize": householdMemberCount,
                                "elderlyCnt": elderlyCount,
                                "householdAssetTotal":householdAssetTotal,
                                "householdIncomeTotal":householdIncomeTotal,
                                "householdIncomeAssets":tmpincomeAssets,
                                "assessmentDate":moment().format("YYYY-MM-DD") ,
                                "lang":lang,
                                "reviewResult":reviewResult,
                                "review_income":review_income,
                                "review_asset":review_asset,
                            });
                            /*open preview*/                   
                            axios({
                                method: 'post',
                                url: APIHost+'/printPreview',
                                data: {
                                    "hhSize": householdMemberCount,
                                    "elderlyCnt": elderlyCount,
                                    "householdAssetTotal":householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                    "householdIncomeTotal":householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                    "householdIncomeAssets":tmpincomeAssets,
                                    "assessmentDate":moment().format("YYYY-MM-DD") ,
                                    "lang":lang,
                                    "reviewResult":reviewResult,
                                    "review_income":review_income,
                                    "review_asset":review_asset,
                                },
                                responseType: 'blob',
                              }).then((resp)=>{
                                //console.log(resp.data);
                                
                                var blob = new Blob([resp.data],{ type: 'application/pdf' });
                                var url = URL.createObjectURL(blob);
                                window.open(url, '__blank');
                              });                
                            }} >  {props.i18n.t("btnPreview")}</Button>
              
              <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
                  /*confirm clear popup, initial */ 
                  setDiagContent(props.i18n.t("msg_confirm_clear_all"));
                  setDiagTitle(props.i18n.t("tab2_header"));
                  setAllReset(true);
                  setOpen2(true);
              }}>{props.i18n.t("btnClearAll")}</Button>
            </Stack> 
    <Grid container spacing={2} sx={{ py:2 }}>
      <Grid item xs={12}  sx={{ ml:1 }}>
        <Typography  paragraph variant="h3"  color="grey.500" align="left"  display="inline"> <br/> </Typography>
      </Grid>
      
    </Grid>
    <Box sx={{border: 1, px:3 , backgroundColor: 'grey.100'}} >
      <Stack>
      <Typography  paragraph > </Typography>
      <Typography  paragraph variant="h4"  align="center" sx={{textDecoration: 'underline'}}> {props.i18n.t("tab1_instruct_title")} </Typography>
      </Stack>      
        
         <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h5" component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1")}}/></Typography>
         <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',mt:2,}}> {props.i18n.t("tab1_instruct_p2_title")} </Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h5" > {props.i18n.t("tab1_instruct_p2")}</Typography>
         <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',mt:2}}> {props.i18n.t("tab1_instruct_p3_title")}</Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h5" component={'span'}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3")}}/></Typography>
         <Typography variant="h5" ><br/></Typography>
         <Typography variant="h6" component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r1")}}/></Typography>
         <Typography variant="h6" component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r2")}}/></Typography>
         <Typography variant="h6" component={'span'} ><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r3")}}/></Typography>         
         <Typography variant="h5" component={'span'} ><br/></Typography>         
    </Box>
    <Typography  paragraph > </Typography>
    <Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("income");props.onNext();}} sx={{fontSize: 22}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack>
    </Box>
    <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

export {ECalApplicationFormBodyInfoDesktop}