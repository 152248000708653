import * as React from 'react';
import {Typography, Grid, Box, Tabs ,Tab,
  IconButton, Accordion, AccordionSummary, AccordionDetails,Stack,
  RadioGroup,Radio ,Select,FormControl,FormControlLabel ,TextField ,MenuItem ,InputLabel } from '@mui/material';
  import {useSelector,} from 'react-redux';
  import { NumericFormat, NumericFormatProps } from 'react-number-format';  
  function  ECalApplicationFormBodyHead(props){
    const handleChange = (event, newValue) => {    
      props.onChange(newValue)
    };
    let actionSX = {border:1, borderRadius:1 ,backgroundColor: 'grey.100' ,  'min-heigth':'40px','min-width':'76px',p:1}
   
    return(
      <Box   sx={{  backgroundColor: 'grey.100', borderRadius:1 }} >
        <Tabs sx={{ backgroundColor: 'grey.100',pt:1,px:1 }} value={props.stepVal} onChange={handleChange}>
        
          <Tab
            label=" "
            wrapped
            value="info"
            icon={<img  width="28rem" heigth = "28rem" src="/images/info.png" />}
            disabled={false}
            sx={actionSX}
          />
          <Tab
            label=" "
            wrapped
            value="income"
            disabled={props.enabledStep<2}
            icon={<img  width="28rem" heigth = "28rem" src="/images/income.png" />}
            sx={{...actionSX,opacity: props.enabledStep<2?0.4:1}}
          />
          <Tab
            label=" "
            wrapped
            value="asset"
            disabled={props.enabledStep<3}
            icon={<img width="28rem" heigth = "28rem" src="/images/asset.png" />}
            sx={{...actionSX,opacity: props.enabledStep<3?0.4:1}}
          />
          <Tab 
          disabled={props.enabledStep<4}
          wrapped
          label=" " value="result" icon={<img width="28rem" heigth = "28rem" src="/images/result.png" />} 
          sx={{...actionSX,opacity: props.enabledStep<4?0.4:1}}/>
        
        </Tabs>
      </Box>
      )
  }

  function  ECalApplicationFormBodyHeadDesktop(props){
    const handleChange = (event, newValue) => {    
      props.onChange(newValue)
    };
    let actionSX = {border:1, borderRadius:1 ,backgroundColor: 'grey.100' }
   
    return(
      <Box   sx={{  backgroundColor: 'grey.100', borderRadius:1 }} >
        <Tabs sx={{ backgroundColor: 'grey.100',pt:1 }} value={props.stepVal} onChange={handleChange}>
        
          <Tab
            label={<Typography variant="h5"  sx={{ fontSize: 28,fontWeight: 'medium', mb:4}}>  <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_header")}}/> </Typography>}
            value="info"
            icon={<></>}
            disabled={false}
            sx={actionSX}
          />
          <img  width="64rem" heigth = "32rem" src="/images/right_arrow.png" />
          <Tab
            label={
              <div align="left">
                <Typography variant="h5" sx={{ fontSize: 28,fontWeight: 'medium' }}>
                <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab2_header")}}/>
                </Typography>
                <Typography variant="h5" sx={{ fontSize: 28,fontWeight: 'medium' }}>
                  {props.ecalcState.householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})}
                </Typography>
              </div>             
            }
            value="income"
            disabled={props.enabledStep<2}
            icon={<></>}
            sx={{...actionSX,opacity: props.enabledStep<2?0.4:1}}
          /><img   width="64rem" heigth = "32rem" src="/images/right_arrow.png" />
          <Tab
            label={              
              <div align="left">
                <Typography variant="h5" sx={{ fontSize: 28,fontWeight: 'medium' }}>
                <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab3_header")}}/>
                </Typography>
                <Typography variant="h5" sx={{ fontSize: 28,fontWeight: 'medium' }}>
                  {props.ecalcState.householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})}
                </Typography>
              </div>             
            }
            value="asset"
            disabled={props.enabledStep<3}
            icon={<></>}
            sx={{...actionSX,opacity: props.enabledStep<3?0.4:1}}
          /><img   width="64rem" heigth = "32rem" src="/images/right_arrow.png" />
          <Tab 
          disabled={props.enabledStep<4}
          label={  <Typography variant="h5" sx={{ fontSize: 28,fontWeight: 'medium', mb:4 }}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab5_header")}}/> </Typography>} 
          value="result" icon={<></>} 
          sx={{...actionSX,opacity: props.enabledStep<4?0.4:1}}/>
        
        </Tabs>
      </Box>
      )
  }

  const FormGridItemGreyHeaderDesktop = (props)=>{
    
    return(
      <>
          <Grid item xs={12} sx={{backgroundColor:'grey.200',height:'4.65rem',p:0.5}}>
            <Typography variant="h5" display="inline"  sx={{lineHeight:1,}} >{props.itemName}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png" width="18px"/></IconButton></Typography>            
          </Grid>
          
      </>
    )
  }
  const FormGridItemHeaderDesktop = (props)=>{
    
    return(
      <>
          <Grid item xs={12} sx={{height:'4.65rem',p:0.5}}>
            <Typography variant="h5" display="inline">{props.itemName}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>            
          </Grid>
      </>
    )
  }

  const FormGridItemGreyHeaderDesktop2 = (props)=>{
    
    return(
      <>
          <Grid item xs={12} sx={{backgroundColor:'grey.200',height:'4.15rem',p:0.5}}>
            <Typography variant="h5" display="inline">{props.itemName}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>            
          </Grid>
          
      </>
    )
  }
  const FormGridItemHeaderDesktop2 = (props)=>{
    
    return(
      <>
          <Grid item xs={12}  sx={{height:'4.15rem',p:0.5}} >
            <Typography variant="h5" display="inline">{props.itemName}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png"   width="18px"/></IconButton></Typography>            
          </Grid>
      </>
    )
  }

  const FormGridItemGreyDesktop = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return ''
      return textVal
    }
    return(
      <>
          <Grid item xs={12} sx={{backgroundColor:'grey.200'}}>
            <Stack direction="row" alignItems="center" justifyContent="center">
            <NumericFormat  
                customInput={TextField}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                placeholder="$0"

                value={
                  curTextConv()                
                } 
                onChange={(ev)=>{                
                    settextVal(ev.target.value);
                }} 
                
                onBlur={(ev)=>{ 
                    let newVal = Math.max(0,parseFloat(ev.target.value.replace(/[^0-9.-]+/g, '')));           
                    if(newVal<=999999999){
                      props.onTextChange(newVal);               
                    }else{
                      if(ev.target.value === ''){
                        settextVal('')
                        props.onTextChange(0); 
                      }                        
                      else
                        settextVal(props.inputVal)
                    } 
                }} variant="outlined"  align="rignt" 
                sx={{p:1, input: {textAlign: "right",backgroundColor:'#FFF',fontSize:"1.5rem"}}}
                
                inputProps={{
                  sx:{py:1},maxLength :12
                }}
              />
            </Stack>
          </Grid>
      </>
    )
  }

  const FormGridItemGreySubtotalDesktop = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return '$0'
      return textVal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})
    }
    return(
      <>
          <Grid item xs={12} sx={{backgroundColor:'grey.200'}}>
            <Stack direction="row" alignItems="center" justifyContent="center">
            <TextField value={curTextConv()} onChange={(ev)=>{                 
                if(isFinite(parseFloat(ev.target.value))) 
                settextVal(parseFloat(ev.target.value)); 
              }} 
              onBlur={(ev)=>{ props.onTextChange(ev.target.value); }} variant="outlined"  align="rignt" 
              sx={{p:1, input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}
              inputProps={{sx:{py:1}}}
              />
            </Stack>
          </Grid>
      </>
    )
  }

  const FormGridItemDesktop = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return ''
      return textVal
    }
    return(
      <>         
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center" justifyContent="center">
            <NumericFormat  
                customInput={TextField}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                placeholder="$0"

                value={curTextConv()} 
                onChange={(ev)=>{ 
                    settextVal(ev.target.value);
                  }}  
                onBlur={(ev)=>{     
                  let newVal = Math.max(0,parseFloat(ev.target.value.replace(/[^0-9.-]+/g, '')));           
                  if(newVal<=999999999){
                    props.onTextChange(newVal);                  
                  }else{
                    if(ev.target.value === ''){
                      settextVal('')
                      props.onTextChange(0); 
                    }                        
                    else
                      settextVal(props.inputVal)
                  } 
                }} 
                variant="outlined"  align="rignt" sx={{p:1, input: {textAlign: "right",fontSize:"1.5rem"}}}
                
                inputProps={{ sx:{py:1},maxLength :12}}
              />
            </Stack>
          </Grid>
      </>
    )
  }

  const FormGridItemSubtotalDesktop = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return '$0'
      return textVal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})
    }
    return(
      <>         
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <TextField value={curTextConv()} onChange={(ev)=>{ 
                //console.log(ev);
                if(isFinite(parseFloat(ev.target.value))) 
                  settextVal(parseFloat(ev.target.value)); 
                }} 
              onBlur={(ev)=>{ props.onTextChange(ev.target.value); }} variant="outlined"  align="rignt" 
              sx={{p:1, input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}        
              inputProps={{sx:{py:1}}}      
              />
            </Stack>
          </Grid>
      </>
    )
  }

  const FormGridItemGrey = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return ''
      return textVal
    }
    return(
      <>
          <Grid item xs={8} sx={{backgroundColor:'grey.200'}}>
            <Typography variant="h6" display="inline">{props.itemName}<IconButton onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png"/></IconButton></Typography>            
          </Grid>
          <Grid item xs={4} sx={{backgroundColor:'grey.200'}}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <NumericFormat  
                customInput={TextField}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                placeholder="$0"

                value={
                  curTextConv()                
                } 
                onChange={(ev)=>{                
                    settextVal(ev.target.value);
                }} 
                
                onBlur={(ev)=>{ 
                    let newVal = Math.max(0,parseFloat(ev.target.value.replace(/[^0-9.-]+/g, '')));           
                    if(newVal<=999999999){
                      props.onTextChange(newVal);               
                    }else{
                      if(ev.target.value === ''){
                        settextVal('')
                        props.onTextChange(0); 
                      }                        
                      else
                        settextVal(props.inputVal)
                    } 
                }} 
                variant="outlined"  align="rignt" sx={{p:0.5, input: {textAlign: "right",backgroundColor:'#FFF',fontSize:"1rem"}}} inputProps={{sx:{py:0.5},maxLength :12}}
                
              />
            </Stack>
          </Grid>
      </>
    )
  }
  const FormGridItem = (props)=>{
    //console.log(props);
    React.useEffect(()=>{settextVal(props.inputVal)},[props.inputVal])
    const [textVal,settextVal] = React.useState(props.inputVal)
    const curTextConv=()=>{
      //console.log(textVal);
      if(textVal === 0) return ''
      return textVal
    }
    return(
      <>
          <Grid item xs={8} >
            <Typography variant="h6" display="inline">{props.itemName}<IconButton onClick={()=>{props.onHelpClick()}} ><img src="images/question_mark.png"/></IconButton></Typography>            
          </Grid>
          <Grid item xs={4} >
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <NumericFormat  
                customInput={TextField}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                placeholder="$0"

                value={curTextConv()} 
                onChange={(ev)=>{ 
                    settextVal(ev.target.value);
                  }}  
                onBlur={(ev)=>{     
                  let newVal = Math.max(0,parseFloat(ev.target.value.replace(/[^0-9.-]+/g, '')));           
                  if(newVal<=999999999){
                    props.onTextChange(newVal);                  
                  }else{
                    if(ev.target.value === ''){
                      settextVal('')
                      props.onTextChange(0); 
                    }                        
                    else
                      settextVal(props.inputVal)
                  } 
                }} 
                variant="outlined"  align="rignt" sx={{p:0.5, input: {textAlign: "right",backgroundColor:'FFF',fontSize:"1rem"}}}
                
                inputProps={{ sx:{py:0.5},maxLength :12}}
              />
            </Stack>
          </Grid>
      </>
    )
  }

  const SubtotalIncomeAccordionDesktop=(props)=>{
    function incomeReducer(subtotal, val) {
      return subtotal + val;
    }
    return(
    
        <Grid container spacing={1}  >
          <Grid item xs={12} sx={{height:'5rem'}} justifyContent="center">
            <Typography variant="h5" align="center" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")}</Typography>
          </Grid>  
          <Grid item xs={12} sx={{height:'4rem',width:'12rem'}} >
          
          </Grid>  
          <FormGridItemGreySubtotalDesktop  onTextChange={(val)=>{}}  inputVal={ props.householdincomeArry.map(x=>x.salary).reduce(incomeReducer)}/>

          <FormGridItemSubtotalDesktop  onTextChange={(val)=>{}} inputVal={ props.householdincomeArry.map(x=>x.allowance).reduce(incomeReducer) }/>
          <FormGridItemGreySubtotalDesktop   onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.double_pay).reduce(incomeReducer) }/>
          <FormGridItemSubtotalDesktop   onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.benefit_in_kind).reduce(incomeReducer) }/>
          <FormGridItemGreySubtotalDesktop  onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.pension).reduce(incomeReducer) }/>
          <FormGridItemSubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.rental_income).reduce(incomeReducer)}/>
          <FormGridItemGreySubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.sponsor).reduce(incomeReducer) }/>
          <FormGridItemSubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.other_income).reduce(incomeReducer) }/>

          <Grid item xs={12} sx={{backgroundColor:'grey.200'}} >     
        
          </Grid>          
        </Grid>      
    )
}
const SubtotalAssetAccordionDesktop=(props)=>{
  function incomeReducer(subtotal, val) {
    return subtotal + val;
  }
  return(
  
      <Grid container spacing={0}  >
        <Grid item xs={12} sx={{height:'5rem',width:'12rem'}} justifyContent="center">
          <Typography variant="h5" align="center" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")}</Typography>
        </Grid>  

        <FormGridItemSubtotalDesktop  onTextChange={(val)=>{}}  inputVal={ props.householdincomeArry.map(x=>x.cash).reduce(incomeReducer)}/>
        <FormGridItemGreySubtotalDesktop  onTextChange={(val)=>{}} inputVal={ props.householdincomeArry.map(x=>x.saving).reduce(incomeReducer) }/>
        <FormGridItemSubtotalDesktop   onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.investment).reduce(incomeReducer) }/>
        <FormGridItemGreySubtotalDesktop   onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.insurance).reduce(incomeReducer) }/>
        <FormGridItemSubtotalDesktop  onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.property).reduce(incomeReducer) }/>
        <FormGridItemGreySubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.carpark).reduce(incomeReducer)}/>
        <FormGridItemSubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.land).reduce(incomeReducer) }/>
        <FormGridItemGreySubtotalDesktop    onTextChange={(val)=>{}} inputVal={props.householdincomeArry.map(x=>x.other).reduce(incomeReducer) }/>

        <Grid item xs={12} sx={{backgroundColor:'grey.200'}} >     
        </Grid>          
      </Grid>      
  )
}
  const FamilyAccordionDesktop=(props)=>{
    
    const [relation,setRelation]=React.useState(props.householdincomeArry[props.FamilyIdx].patient_rel==""?"none":props.householdincomeArry[props.FamilyIdx].patient_rel);
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    
    const [mouseX, setMouseX] = React.useState();
    const [mouseY, setMouseY] = React.useState();
    

      const handleMouseEnter = (event) => {
        setMouseX(event.pageX);
        setMouseY(event.pageY);    
      }
    return(
      
        <Grid  container spacing={1}  >
          <Grid item xs={12} >
          <Stack  alignItems="center" justifyContent="center" spacing={1} > 
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} > 
              <Typography   variant="h5"  noWrap >{props.i18n.t("tab2_household")}{props.FamilyIdx}  </Typography><Typography variant="h5" color="#f44336"> *</Typography>
              <IconButton onClick={()=>{props.onRemove(props.FamilyIdx)}}>
                <img width="22px" heigth="22px" src="/images/clear_btn.gif"/>
              </IconButton>          
            </Stack>
            <Stack   direction="row" alignItems="center" justifyContent="center" spacing={1} > 
              <TextField select                 
                onMouseEnter={handleMouseEnter}
                SelectProps={{
                  MenuProps: {                    
                    anchorOrigin: {                      
                      vertical: mouseY,
                      horizontal: mouseX
                    },
                     transformOrigin: {
                      vertical: "0",
                      horizontal: "0"
                     },
                    anchorEl:null
                  },
                  
                }}
               margin="0" size="small" justifyContent="center" 
               sx={{width:'13rem',backgroundColor:'#FFF'}}
               defaultValue={props.i18n.t("cboRelation_Default")}
               value={relation} onChange={(ev)=>{setRelation(ev.target.value);props.onChange({item:'patient_rel',val:ev.target.value})}} >              
                <MenuItem value="none" >
                  <em>{props.i18n.t("cboRelation_Default")}</em>
                </MenuItem>
                {props.relationStr.map((item)=>{                
                  return(
                  <MenuItem value={item.value} key={'mi'+item.value}>
                    <em>{item.text}</em>
                  </MenuItem>)
                })}
              </TextField>
              </Stack>
            </Stack>
          </Grid>
          
          <Grid  item xs={12} >
            <Stack direction="row" alignItems="center" justifyContent="center"> <RadioGroup row value={ props.householdincomeArry[props.FamilyIdx].isOver65} onChange={(ev)=>{ props.onChange({item:'isOver65',val:ev.target.value})}}  >
              <FormControlLabel value="true" control={<Radio />}  componentsProps={{typography: { variant: 'h5' }}} label={props.i18n.t("tab2_yes") } />
              <FormControlLabel value="false" control={<Radio />}  componentsProps={{typography: { variant: 'h5' }}} label={props.i18n.t("tab2_no") } />
            </RadioGroup>  </Stack>
          </Grid>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab2_salary")} onHelpClick={()=>{handleClickOpen("tab2_salary_tooltip_title","tab2_salary_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'salary',val:val})}}  inputVal={ props.householdincomeArry[props.FamilyIdx].salary}/>
          <FormGridItemDesktop itemName={ props.i18n.t("tab2_subsidy")} onHelpClick={()=>{handleClickOpen("tab2_subsidy_tooltip_title","tab2_subsidy_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'allowance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].allowance}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_bonus") } onHelpClick={()=>{handleClickOpen("tab2_bonus_tooltip_title","tab2_bonus_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'double_pay',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].double_pay}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_goods")} onHelpClick={()=>{handleClickOpen("tab2_goods_tooltip_title","tab2_goods_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'benefit_in_kind',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].benefit_in_kind}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_retire")} onHelpClick={()=>{handleClickOpen("tab2_retire_tooltip_title","tab2_retire_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'pension',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].pension}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_rent")} onHelpClick={()=>{handleClickOpen("tab2_rent_tooltip_title","tab2_rent_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'rental_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].rental_income}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_help")} onHelpClick={()=>{handleClickOpen("tab2_help_tooltip_title","tab2_help_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'sponsor',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].sponsor}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_other")} onHelpClick={()=>{handleClickOpen("tab2_other_tooltip_title","tab2_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other_income}/>
  
          <Grid item xs={12} sx={{backgroundColor:'grey.200'}} >            
              <Stack direction="row" alignItems="flex-end" justifyContent="center"> 
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" inputProps={{sx:{py:1.35}}}  sx={{p:1,input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>            
              </Stack>
          </Grid>          
        </Grid>
    )
  }

  const FamilyAccordion=(props)=>{
    const [relation,setRelation]=React.useState(props.householdincomeArry[props.FamilyIdx].patient_rel==""?"none":props.householdincomeArry[props.FamilyIdx].patient_rel);
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    const lang = useSelector((state)=> state.config.lang);
    return(
      
      <Accordion  expanded={props.expanded} defaultExpanded={props.defaultExpanded} disableGutters >
        <AccordionSummary
          expandIcon={<img width="22px" heigth="22px" src="/images/up.png" />}
          sx={{backgroundColor:'#009688',color:'#FFF',borderBottom:3,borderColor: 'grey.900'}}
        >{lang=='en'?
          <>
            <Stack >
              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1} > 
                <Typography variant="h6" noWarp >{props.i18n.t("tab2_household")}{props.FamilyIdx}  </Typography><Typography variant="h5" color="#f44336"> *</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1} > 
                <TextField select margin="none" size="small" sx={{width:120,backgroundColor:'#FFF'}} value={relation} onClick={(e)=>{e.stopPropagation()}} onChange={(ev)=>{setRelation(ev.target.value);props.onChange({item:'patient_rel',val:ev.target.value})}} >              
                  <MenuItem value="none" >
                    <em>{props.i18n.t("cboRelation_Default")}</em>
                  </MenuItem>
                  {props.relationStr.map((item)=>{                
                    return(
                    <MenuItem value={item.value} key={'mi'+item.value}>
                      <em>{item.text}</em>
                    </MenuItem>)
                  })}
                </TextField>            
              
              <IconButton onClick={()=>{props.onRemove(props.FamilyIdx)}}>
                  <img width="22px" heigth="22px" src="/images/clear_btn.gif"/>
              </IconButton>
              
              </Stack>
            </Stack>
          </>:<>          
              <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1} > 
                <Typography variant="h6" noWarp >{props.i18n.t("tab2_household")}{props.FamilyIdx}  </Typography><Typography variant="h5" color="#f44336"> *</Typography>
          
                <TextField select margin="none" size="small" sx={{width:120,backgroundColor:'#FFF'}} value={relation} onClick={(e)=>{e.stopPropagation()}} onChange={(ev)=>{setRelation(ev.target.value);props.onChange({item:'patient_rel',val:ev.target.value})}} >              
                  <MenuItem value="none" >
                    <em>{props.i18n.t("cboRelation_Default")}</em>
                  </MenuItem>
                  {props.relationStr.map((item)=>{                
                    return(
                    <MenuItem value={item.value} key={'mi'+item.value}>
                      <em>{item.text}</em>
                    </MenuItem>)
                  })}
                </TextField>            
              
              <IconButton onClick={()=>{props.onRemove(props.FamilyIdx)}}>
                  <img width="22px" heigth="22px" src="/images/clear_btn.gif"/>
              </IconButton>
              
              </Stack>
            
          </>
        }
          
        </AccordionSummary>
        <AccordionDetails >
          <Grid container spacing={0} >
            <Grid item xs={8} >
              <Typography variant="body" >{props.i18n.t("tab2_is_elderly") }</Typography>
            </Grid>
            <Grid item xs={4} >
              <Stack direction="row" alignItems="flex-end" justifyContent="flex-end"> 
                <RadioGroup row size="small"  sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 12,
                            },}} 
                            value={ props.householdincomeArry[props.FamilyIdx].isOver65} onChange={(ev)=>{ props.onChange({item:'isOver65',val:ev.target.value})}}  >
                  <FormControlLabel value="true" control={<Radio />} label={props.i18n.t("tab2_yes") } />
                  <FormControlLabel value="false" control={<Radio />} label={props.i18n.t("tab2_no") } />
                </RadioGroup>  
              </Stack>
            </Grid>
            <FormGridItemGrey itemName={props.i18n.t("tab2_salary")} onHelpClick={()=>{handleClickOpen("tab2_salary_tooltip_title","tab2_salary_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'salary',val:val})}}  inputVal={ props.householdincomeArry[props.FamilyIdx].salary}/>
            <FormGridItem itemName={ props.i18n.t("tab2_subsidy")} onHelpClick={()=>{handleClickOpen("tab2_subsidy_tooltip_title","tab2_subsidy_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'allowance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].allowance}/>
            <FormGridItemGrey  itemName={props.i18n.t("tab2_bonus") } onHelpClick={()=>{handleClickOpen("tab2_bonus_tooltip_title","tab2_bonus_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'double_pay',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].double_pay}/>
            <FormGridItem  itemName={props.i18n.t("tab2_goods")} onHelpClick={()=>{handleClickOpen("tab2_goods_tooltip_title","tab2_goods_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'benefit_in_kind',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].benefit_in_kind}/>
            <FormGridItemGrey  itemName={props.i18n.t("tab2_retire")} onHelpClick={()=>{handleClickOpen("tab2_retire_tooltip_title","tab2_retire_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'pension',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].pension}/>
            <FormGridItem  itemName={props.i18n.t("tab2_rent")} onHelpClick={()=>{handleClickOpen("tab2_rent_tooltip_title","tab2_rent_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'rental_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].rental_income}/>
            <FormGridItemGrey  itemName={props.i18n.t("tab2_help")} onHelpClick={()=>{handleClickOpen("tab2_help_tooltip_title","tab2_help_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'sponsor',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].sponsor}/>
            <FormGridItem  itemName={props.i18n.t("tab2_other")} onHelpClick={()=>{handleClickOpen("tab2_other_tooltip_title","tab2_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other_income}/>
    
            <Grid item xs={12} sx={{backgroundColor:'grey.200'}}>
              <Stack direction="row" alignItems="center"  justifyContent="flex-end"> <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")} </Typography>                       
                <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" sx={{p:0.5, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem"}}}/>
              </Stack>
            </Grid>          
          </Grid>
        </AccordionDetails>
      </Accordion>
      
    )
  }

  const PatientAccordionHeaderDesktop=(props)=>{    
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    return(
        <Grid container spacing={0} >
          <Grid item xs={12} sx={{backgroundColor:'#FFF',color:'#000',height:'6.5rem',width:'22rem'}} > 
            <Typography variant="h5" paragraph> </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="h5"  sx={{width:'30rem'}} noWrap>{props.i18n.t("tab2_is_elderly") }</Typography>
          </Grid>
          <FormGridItemGreyHeaderDesktop itemName={props.i18n.t("tab2_salary")} onHelpClick={()=>{handleClickOpen("tab2_salary_tooltip_title","tab2_salary_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'salary',val:val})}}  />
          <FormGridItemHeaderDesktop itemName={ props.i18n.t("tab2_subsidy")} onHelpClick={()=>{handleClickOpen("tab2_subsidy_tooltip_title","tab2_subsidy_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'allowance',val:val})}} />
          <FormGridItemGreyHeaderDesktop  itemName={props.i18n.t("tab2_bonus") } onHelpClick={()=>{handleClickOpen("tab2_bonus_tooltip_title","tab2_bonus_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'double_pay',val:val})}} />
          <FormGridItemHeaderDesktop  itemName={props.i18n.t("tab2_goods")} onHelpClick={()=>{handleClickOpen("tab2_goods_tooltip_title","tab2_goods_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'benefit_in_kind',val:val})}} />
          <FormGridItemGreyHeaderDesktop  itemName={props.i18n.t("tab2_retire")} onHelpClick={()=>{handleClickOpen("tab2_retire_tooltip_title","tab2_retire_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'pension',val:val})}} />
          <FormGridItemHeaderDesktop  itemName={props.i18n.t("tab2_rent")} onHelpClick={()=>{handleClickOpen("tab2_rent_tooltip_title","tab2_rent_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'rental_income',val:val})}} />
          <FormGridItemGreyHeaderDesktop  itemName={props.i18n.t("tab2_help")} onHelpClick={()=>{handleClickOpen("tab2_help_tooltip_title","tab2_help_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'sponsor',val:val})}} />
          <FormGridItemHeaderDesktop  itemName={props.i18n.t("tab2_other")} onHelpClick={()=>{handleClickOpen("tab2_other_tooltip_title","tab2_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other_income',val:val})}} />
          <Grid item xs={12} sx={{backgroundColor:'grey.200',height:'5rem',p:0.5,}}>
             <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")} </Typography>                       
          </Grid>          
        </Grid>
    )
  }
  const PatientAccordionDesktop=(props)=>{
        const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    
    return(
      
        <Grid container spacing={1}  >
          <Grid item xs={12} sx={{height:'5rem'}} justifyContent="center">
            <Typography variant="h5" align="center" > {props.i18n.t("tab2_patient")}</Typography>
          </Grid>  
          <Grid item xs={12} sx={{height:'4rem',width:'15rem'}}  alignItems="bottom" >
            <Stack direction="row" alignItems="bottom" justifyContent="center"> 
              <RadioGroup row  alignItems="bottom" sx={{mb:0}} value={ props.householdincomeArry[props.FamilyIdx].isOver65} onChange={(ev)=>{ props.onChange({item:'isOver65',val:ev.target.value})}}  >
                <FormControlLabel value="true" control={<Radio />} componentsProps={{typography: { variant: 'h5' }}} label={props.i18n.t("tab2_yes")} />
                <FormControlLabel value="false" control={<Radio />}  componentsProps={{typography: { variant: 'h5' }}} label={props.i18n.t("tab2_no")} />
              </RadioGroup>  
            </Stack>
          </Grid>  
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab2_salary")} onHelpClick={()=>{handleClickOpen("tab2_salary_tooltip_title","tab2_salary_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'salary',val:val})}}  inputVal={ props.householdincomeArry[props.FamilyIdx].salary}/>

          <FormGridItemDesktop itemName={ props.i18n.t("tab2_subsidy")} onHelpClick={()=>{handleClickOpen("tab2_subsidy_tooltip_title","tab2_subsidy_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'allowance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].allowance}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_bonus") } onHelpClick={()=>{handleClickOpen("tab2_bonus_tooltip_title","tab2_bonus_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'double_pay',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].double_pay}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_goods")} onHelpClick={()=>{handleClickOpen("tab2_goods_tooltip_title","tab2_goods_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'benefit_in_kind',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].benefit_in_kind}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_retire")} onHelpClick={()=>{handleClickOpen("tab2_retire_tooltip_title","tab2_retire_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'pension',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].pension}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_rent")} onHelpClick={()=>{handleClickOpen("tab2_rent_tooltip_title","tab2_rent_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'rental_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].rental_income}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab2_help")} onHelpClick={()=>{handleClickOpen("tab2_help_tooltip_title","tab2_help_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'sponsor',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].sponsor}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab2_other")} onHelpClick={()=>{handleClickOpen("tab2_other_tooltip_title","tab2_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other_income}/>
  

          <Grid item xs={12} sx={{backgroundColor:'grey.200'}} >     
              <Stack direction="row" alignItems="flex-end" justifyContent="center">        
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" inputProps={{sx:{py:1.35}}}   sx={{p:1,input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>            
              </Stack>
          </Grid>          
        </Grid>      
    )
  }
  const PatientAccordion=(props)=>{
    
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    
    
    return(
      
      <Accordion defaultExpanded={true} disableGutters>
        <AccordionSummary
          expandIcon={<img width="22px" heigth="22px" src="/images/up.png" />}
          sx={{backgroundColor:'#009688',color:'#FFF',borderBottom:3,borderColor: 'grey.900',m:0,px:2}}
        >
          <Typography variant="h6" > {props.i18n.t("tab2_patient")}</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <Grid container spacing={0} >
          <Grid item xs={8} >
            <Typography variant="body" >{props.i18n.t("tab2_is_elderly") }</Typography>
          </Grid>
          <Grid item xs={4} >
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end"> 
              <RadioGroup row size="small"  sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 12,
                          },}} 
                          value={ props.householdincomeArry[props.FamilyIdx].isOver65} onChange={(ev)=>{ props.onChange({item:'isOver65',val:ev.target.value})}}  >
                <FormControlLabel value="true" control={<Radio />} label={props.i18n.t("tab2_yes")} />
                <FormControlLabel value="false" control={<Radio />} label={props.i18n.t("tab2_no")} />
              </RadioGroup>  
            </Stack>
          </Grid>  
          <FormGridItemGrey itemName={props.i18n.t("tab2_salary")} onHelpClick={()=>{handleClickOpen("tab2_salary_tooltip_title","tab2_salary_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'salary',val:val})}}  inputVal={ props.householdincomeArry[props.FamilyIdx].salary}/>
          <FormGridItem itemName={ props.i18n.t("tab2_subsidy")} onHelpClick={()=>{handleClickOpen("tab2_subsidy_tooltip_title","tab2_subsidy_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'allowance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].allowance}/>
          <FormGridItemGrey  itemName={props.i18n.t("tab2_bonus") } onHelpClick={()=>{handleClickOpen("tab2_bonus_tooltip_title","tab2_bonus_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'double_pay',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].double_pay}/>
          <FormGridItem  itemName={props.i18n.t("tab2_goods")} onHelpClick={()=>{handleClickOpen("tab2_goods_tooltip_title","tab2_goods_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'benefit_in_kind',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].benefit_in_kind}/>
          <FormGridItemGrey  itemName={props.i18n.t("tab2_retire")} onHelpClick={()=>{handleClickOpen("tab2_retire_tooltip_title","tab2_retire_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'pension',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].pension}/>
          <FormGridItem  itemName={props.i18n.t("tab2_rent")} onHelpClick={()=>{handleClickOpen("tab2_rent_tooltip_title","tab2_rent_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'rental_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].rental_income}/>
          <FormGridItemGrey  itemName={props.i18n.t("tab2_help")} onHelpClick={()=>{handleClickOpen("tab2_help_tooltip_title","tab2_help_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'sponsor',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].sponsor}/>
          <FormGridItem  itemName={props.i18n.t("tab2_other")} onHelpClick={()=>{handleClickOpen("tab2_other_tooltip_title","tab2_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other_income',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other_income}/>
  

          <Grid item xs={12} sx={{backgroundColor:'grey.200'}}>
            <Stack direction="row" alignItems="center"  justifyContent="flex-end"> <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")} </Typography>                       
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" sx={{p:0.5, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem"}}}/>
            </Stack>
          </Grid>          
        </Grid>
        </AccordionDetails>
      </Accordion>
      
    )
  }
  const FamilyAssetAccordion=(props)=>{
    
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    
    let rel_str = props.relationStr.filter(item=>item.value === props.householdincomeArry[props.FamilyIdx].patient_rel).map(item=>item.text)[0] ;
    
    return(
      
      <Accordion defaultExpanded={true} disableGutters>
        <AccordionSummary
          expandIcon={<img width="22px" heigth="22px" src="/images/up.png" />}
          sx={{backgroundColor:'#009688',color:'#FFF',borderBottom:3,borderColor: 'grey.900'}}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1} > 
          <Typography variant="h5" >{props.i18n.t("tab3_household")} {props.FamilyIdx}  </Typography><Typography variant="h5" color="#f44336"> </Typography>
          <Typography variant="h5" > {rel_str} </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails >
        <Grid container spacing={0} >
          <FormGridItem itemName={ props.i18n.t("tab3_cash")} onHelpClick={()=>{handleClickOpen( "tab3_cash_tooltip_title","tab3_cash_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'cash',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].cash}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_saving")} onHelpClick={()=>{handleClickOpen("tab3_saving_tooltip_title","tab3_saving_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'saving',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].saving}/>
          <FormGridItem itemName={props.i18n.t("tab3_investment")} onHelpClick={()=>{handleClickOpen("tab3_investment_tooltip_title","tab3_investment_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'investment',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].investment}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_insurance")} onHelpClick={()=>{handleClickOpen( "tab3_insurance_tooltip_title","tab3_insurance_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'insurance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].insurance}/>
          <FormGridItem itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen( "tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'property',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].property}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_carpark")} onHelpClick={()=>{handleClickOpen( "tab3_carpark_tooltip_title","tab3_carpark_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'carpark',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].carpark}/>
          <FormGridItem  itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen("tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'land',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].land}/>
          <FormGridItemGrey  itemName={props.i18n.t("tab3_other")} onHelpClick={()=>{handleClickOpen( "tab3_other_tooltip_title","tab3_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other}/>
          
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center"  justifyContent="flex-end"> <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")} </Typography>                       
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" sx={{p:0.5, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem",backgroundColor:'grey.200'}}}/>
            </Stack>
          </Grid>          
        </Grid>
        </AccordionDetails>
      </Accordion>
      
    )
  }
  const FamilyAssetAccordionDesktop=(props)=>{
    
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    
    let rel_str = props.relationStr.filter(item=>item.value === props.householdincomeArry[props.FamilyIdx].patient_rel).map(item=>item.text)[0] ;
    
    return(
        <Grid container spacing={0} >
            <Grid item xs={12} sx={{height:'5rem',width:'12rem'}} justifyContent="center">            
                <Stack direction="row" alignItems="center"  justifyContent="center">  
                  <Typography variant="h5"  align="center"  justifyContent="center" sx={{width:'10rem'}} >{props.i18n.t("tab3_household")} {props.FamilyIdx}   </Typography>
                </Stack>
                <Typography variant="h5"  align="center" > {rel_str} </Typography>            
              
            </Grid>

          <FormGridItemDesktop itemName={ props.i18n.t("tab3_cash")} onHelpClick={()=>{handleClickOpen( "tab3_cash_tooltip_title","tab3_cash_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'cash',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].cash}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_saving")} onHelpClick={()=>{handleClickOpen("tab3_saving_tooltip_title","tab3_saving_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'saving',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].saving}/>
          <FormGridItemDesktop itemName={props.i18n.t("tab3_investment")} onHelpClick={()=>{handleClickOpen("tab3_investment_tooltip_title","tab3_investment_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'investment',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].investment}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_insurance")} onHelpClick={()=>{handleClickOpen( "tab3_insurance_tooltip_title","tab3_insurance_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'insurance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].insurance}/>
          <FormGridItemDesktop itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen( "tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'property',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].property}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_carpark")} onHelpClick={()=>{handleClickOpen( "tab3_carpark_tooltip_title","tab3_carpark_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'carpark',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].carpark}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen("tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'land',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].land}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab3_other")} onHelpClick={()=>{handleClickOpen( "tab3_other_tooltip_title","tab3_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other}/>
          
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center"  justifyContent="center">                       
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" inputProps={{sx:{py:1.35}}}    sx={{p:1,input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem" }}}/>
            </Stack>
          </Grid>          
        </Grid>
      
    )
  }

  const PatientAssetAccordionHeaderDesktop=(props)=>{
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    return(
      
        <Grid container spacing={1} >          
        
          <Grid item xs={12} sx={{backgroundColor:'#FFF',color:'#000',height:'5.5rem',width:'24rem'}} >
            <Typography variant="h5"  > </Typography>
          </Grid>
          <FormGridItemHeaderDesktop2 itemName={ props.i18n.t("tab3_cash")} onHelpClick={()=>{handleClickOpen( "tab3_cash_tooltip_title","tab3_cash_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'cash',val:val})}} />
          <FormGridItemGreyHeaderDesktop2 itemName={props.i18n.t("tab3_saving")} onHelpClick={()=>{handleClickOpen("tab3_saving_tooltip_title","tab3_saving_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'saving',val:val})}} />
          <FormGridItemHeaderDesktop2 itemName={props.i18n.t("tab3_investment")} onHelpClick={()=>{handleClickOpen("tab3_investment_tooltip_title","tab3_investment_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'investment',val:val})}}/>
          <FormGridItemGreyHeaderDesktop2 itemName={props.i18n.t("tab3_insurance")} onHelpClick={()=>{handleClickOpen( "tab3_insurance_tooltip_title","tab3_insurance_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'insurance',val:val})}} />
          <FormGridItemHeaderDesktop2 itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen( "tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'property',val:val})}} />
          <FormGridItemGreyHeaderDesktop2 itemName={props.i18n.t("tab3_carpark")} onHelpClick={()=>{handleClickOpen( "tab3_carpark_tooltip_title","tab3_carpark_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'carpark',val:val})}} />
          <FormGridItemHeaderDesktop2  itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen("tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'land',val:val})}} />
          <FormGridItemGreyHeaderDesktop2  itemName={props.i18n.t("tab3_other")} onHelpClick={()=>{handleClickOpen( "tab3_other_tooltip_title","tab3_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other',val:val})}} />
        
          <Grid item xs={12} >
            <Stack direction="row"> 
              <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',p:0.5}}> {props.i18n.t("sub_total")} </Typography>                       
            </Stack>
          </Grid>     
        </Grid>
      
      
    )
  }
  const PatientAssetAccordionDesktop=(props)=>{
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    return(
    
        <Grid container spacing={0} >         
        <Grid item xs={12} sx={{height:'5rem',width:'12rem'}} justifyContent="center" >
            <Typography variant="h5"  align="center" > {props.i18n.t("tab2_patient")}</Typography> 
        </Grid>
        
          <FormGridItemDesktop itemName={ props.i18n.t("tab3_cash")} onHelpClick={()=>{handleClickOpen( "tab3_cash_tooltip_title","tab3_cash_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'cash',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].cash}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_saving")} onHelpClick={()=>{handleClickOpen("tab3_saving_tooltip_title","tab3_saving_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'saving',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].saving}/>
          <FormGridItemDesktop itemName={props.i18n.t("tab3_investment")} onHelpClick={()=>{handleClickOpen("tab3_investment_tooltip_title","tab3_investment_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'investment',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].investment}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_insurance")} onHelpClick={()=>{handleClickOpen( "tab3_insurance_tooltip_title","tab3_insurance_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'insurance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].insurance}/>
          <FormGridItemDesktop itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen( "tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'property',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].property}/>
          <FormGridItemGreyDesktop itemName={props.i18n.t("tab3_carpark")} onHelpClick={()=>{handleClickOpen( "tab3_carpark_tooltip_title","tab3_carpark_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'carpark',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].carpark}/>
          <FormGridItemDesktop  itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen("tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'land',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].land}/>
          <FormGridItemGreyDesktop  itemName={props.i18n.t("tab3_other")} onHelpClick={()=>{handleClickOpen( "tab3_other_tooltip_title","tab3_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other}/>
        
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center"  justifyContent="center">             
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" inputProps={{sx:{py:1.35}}} sx={{p:1,input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
            </Stack>
          </Grid>     
        </Grid>
      
    )
  }
  const PatientAssetAccordion=(props)=>{
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      props.onShowHelp(helpTipsTitleKey,helpTipsKey);
    };
    return(
      
      <Accordion  defaultExpanded={true} disableGutters>
        <AccordionSummary
          expandIcon={<img width="22px" heigth="22px" src="/images/up.png" />}
          sx={{backgroundColor:'#009688',color:'#FFF',borderBottom:3,borderColor: 'grey.900'}}
        >
          <Typography variant="h5" > {props.i18n.t("tab2_patient")}</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <Grid container spacing={0} >          
        <FormGridItem itemName={ props.i18n.t("tab3_cash")} onHelpClick={()=>{handleClickOpen( "tab3_cash_tooltip_title","tab3_cash_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'cash',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].cash}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_saving")} onHelpClick={()=>{handleClickOpen("tab3_saving_tooltip_title","tab3_saving_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'saving',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].saving}/>
          <FormGridItem itemName={props.i18n.t("tab3_investment")} onHelpClick={()=>{handleClickOpen("tab3_investment_tooltip_title","tab3_investment_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'investment',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].investment}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_insurance")} onHelpClick={()=>{handleClickOpen( "tab3_insurance_tooltip_title","tab3_insurance_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'insurance',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].insurance}/>
          <FormGridItem itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen( "tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'property',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].property}/>
          <FormGridItemGrey itemName={props.i18n.t("tab3_carpark")} onHelpClick={()=>{handleClickOpen( "tab3_carpark_tooltip_title","tab3_carpark_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'carpark',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].carpark}/>
          <FormGridItem  itemName={props.i18n.t("tab3_property")} onHelpClick={()=>{handleClickOpen("tab3_property_tooltip_title","tab3_property_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'land',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].land}/>
          <FormGridItemGrey  itemName={props.i18n.t("tab3_other")} onHelpClick={()=>{handleClickOpen( "tab3_other_tooltip_title","tab3_other_tooltip_desc")}} onTextChange={(val)=>{props.onChange({item:'other',val:val})}} inputVal={ props.householdincomeArry[props.FamilyIdx].other}/>
        
          <Grid item xs={12} >
            <Stack direction="row" alignItems="center"  justifyContent="flex-end"> <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}> {props.i18n.t("sub_total")} </Typography>                       
              <TextField value={props.householdincomeArry[props.FamilyIdx].subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" sx={{p:0.5, width:'6.3rem', input: {px:1,py:0.5,textAlign: "right",fontSize:"1rem",backgroundColor:'grey.200'}}}/>
            </Stack>
          </Grid>     
        </Grid>
        </AccordionDetails>
      </Accordion>
      
    )
  }
  const addHouseholdincomeItem =(householdincomeArry)=>{
    return [ // with a new array
            ...householdincomeArry, // that contains all the old items
            { id: householdincomeArry.length, 
              patient_rel: "Parent" ,
              salary:0,
              allowance:0,
              double_pay:0,
              benefit_in_kind:0,
              pension:0,
              rental_income:0,
              sponsor:0,
              other_income:0,
              isOver65:false,
              subTotal:0,
              cash:0,
              saving:0,
              investment:0,
              insurance:0,
              property:0,
              carpark:0,
              land:0,
              other:0,
              subTotal2:0,
            } // and one new item at the end
          ]
  }
  const addHouseholdincomeItemExist =(householdincomeArry,newitem)=>{
    return [ // with a new array
            ...householdincomeArry, // that contains all the old items
            { id: householdincomeArry.length, ...newitem
            } // and one new item at the end
          ]
  }
  
  const updateHouseholdincomeItem=(householdincomeArry,index,itemField,itemVal)=>{    
    //console.log(householdincomeArry);
    return householdincomeArry.map((item, i) => {
      if (index === i) {
        //console.log('update:'+itemField+' '+itemVal);        
        return { ...item, [itemField]:itemVal };
      } else {
        return item;
      }
    });
  }
  const removeHouseholdincomeItem=(householdincomeArry,index)=>{
    
    return householdincomeArry.filter( a =>a.id !== index).map((item,idx)=>{ return {...item,'id':idx}});
    
  }
  
  const convFloat = (input)=>{
    var tmp = parseFloat(input);
    if( isFinite(tmp))
      return tmp
    return 0;
  }
  export {ECalApplicationFormBodyHead,FormGridItemGrey,FormGridItem,FamilyAccordion,PatientAccordion,
          FamilyAssetAccordion,PatientAssetAccordion, ECalApplicationFormBodyHeadDesktop,PatientAssetAccordionHeaderDesktop,PatientAssetAccordionDesktop,FamilyAssetAccordionDesktop,
          addHouseholdincomeItem,addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem,SubtotalAssetAccordionDesktop,
          convFloat,PatientAccordionHeaderDesktop,PatientAccordionDesktop,FamilyAccordionDesktop,SubtotalIncomeAccordionDesktop}