import * as React from 'react';
import {Stack,Typography, Grid, Box, Button,} from '@mui/material';
import {ECalApplicationFormBodyHead} from './ECalApplicationFormCommon.js';

function ECalApplicationFormBodyInfo(props){

    return(
    <>
    <Box   sx={{ p:1,my:1,mx:2,border:1, borderRadius:1 }} >
    <ECalApplicationFormBodyHead onChange={props.onChange} stepVal={props.stepVal} enabledStep={props.enabledStep}/>
    <Grid container spacing={2} sx={{ py:1 }}>
      <Grid item xs={12} borderBottom={1} sx={{px:3, ml:2,my:2 }}>
        <Typography  paragraph variant="h5"  color="grey.500" align="left"  display="inline">{props.i18n.t("tab1_header")}</Typography>
      </Grid>
      
    </Grid>
    <Box height={ (window.innerHeight/1.82)+'px'} sx={{border: 1, px:2 , overflow: "hidden" , backgroundColor: 'grey.100'}} >
      <Stack>
      <Typography  paragraph > </Typography>
      <Typography  paragraph variant="h6"  align="center" sx={{textDecoration: 'underline'}}> {props.i18n.t("tab1_instruct_title")} </Typography>
      </Stack>      
        <Box height={ (window.innerHeight/2.5)+'px'} sx={{border: 1, p:1 , overflow: "hidden",overflowY: "scroll", backgroundColor: '#FFF'}} >
         <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h6" component={'span'} sx={{lineHeight:1,}}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1")}}/></Typography>
         <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p2_title")} </Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h6" sx={{lineHeight:1,}}> {props.i18n.t("tab1_instruct_p2")}</Typography>
         <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p3_title")}</Typography> <Typography variant="h5" >: </Typography></Stack>
         <Typography variant="h6" component={'span'} sx={{lineHeight:1,}}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3")}}/></Typography>
         <Typography variant="h6" ><br/></Typography>
         <Typography variant="h6" component={'span'} sx={{lineHeight:1,}}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r1")}}/></Typography>
         <Typography variant="h6" component={'span'} sx={{lineHeight:1,}}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r2")}}/></Typography>
         <Typography variant="h6" component={'span'} sx={{lineHeight:1,}}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_r3")}}/></Typography>         
        </Box>                
    </Box>
    <Typography  paragraph > </Typography>
    <Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("income");props.onNext();}} sx={{backgroundColor:'grey.200',}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack>
    </Box>
    </>
  )
}

export {ECalApplicationFormBodyInfo}