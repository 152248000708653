
import * as React from 'react';

import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField } from '@mui/material';
import {ECalApplicationFormBodyHeadDesktop,PatientAccordionDesktop,PatientAccordionHeaderDesktop,FamilyAccordionDesktop,
        SubtotalIncomeAccordionDesktop,
        convFloat,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import { useDispatch, useSelector} from 'react-redux'
import { setState} from '../store/eCalcStore.js';
import moment from 'moment';
import axios from 'axios';
const APIHost = window.APIHost;




function ECalApplicationFormBodyIncome(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    let elderlyCount = props.ecalcState.elderlyCount;
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const lang = useSelector((state)=> state.config.lang);
    const [reviewResult,setReviewResult] =React.useState(props.i18n.t("tab5_eligible"));
    const [review_income,setReview_income] = React.useState(props.i18n.t("tab5_income_01_b_50"));
    const [review_asset,setReview_asset] = React.useState( props.i18n.t("tab5_asset_01_b_lmt"));
    const [reviewResultTip,setReviewResultTip] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);
        let householdIncomeAssets2 = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});
        householdIncomeTotal =0;
        //(householdIncomeAssets2);
        dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
        if(allReset){ props.onReset();}
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]   
    return(
      <>
      <Box   sx={{ p:2,m:2,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHeadDesktop onChange={props.onChange} ecalcState={props.ecalcState} stepVal={props.stepVal}  enabledStep={props.enabledStep} i18n={props.i18n}/>
      
      <Stack  sx={{ ml:1 }}>
        <Typography variant="h5" > <br/></Typography>
        <Grid container spacing={1} >
          <Grid item xs={7} >
          <Stack direction="row" alignItems="center">
            <Typography variant="h5"  color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
            <Button  variant="text" startIcon={<img src="/images/blue_cross.png"/>} sx={{ml:5,fontSize: 22,color: '#009688'}} onClick={()=>{   
                let householdIncomeAssets2 = addHouseholdincomeItemExist(householdIncomeAssets, {    
                                                                              patient_rel:'',
                                                                              salary:0,
                                                                              allowance:0,
                                                                              double_pay:0,
                                                                              benefit_in_kind:0,
                                                                              pension:0,
                                                                              rental_income:0,
                                                                              sponsor:0,
                                                                              other_income:0,
                                                                              isOver65:false,
                                                                              subTotal:0,
                                                                              cash:0,
                                                                              saving:0,
                                                                              investment:0,
                                                                              insurance:0,
                                                                              property:0,
                                                                              carpark:0,
                                                                              land:0,
                                                                              other:0,
                                                                              subTotal2:0,
                                                                            });
                householdMemberCount =householdIncomeAssets2.length;
                dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));                                                                      
              }} > {props.i18n.t("tab2_btnAddMember")} 
            </Button>  
            </Stack>
            <Typography variant="h5"  color="grey.700" paragraph> {props.i18n.t("tab2_elderly_count")}:{elderlyCount}</Typography> <br/>
          
          </Grid>
          <Grid item xs={5} >
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
              <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{ 
                              const relationStr =[                        
                                { value:"Patient" , text: props.i18n.t("tab2_patient") } ,
                                { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
                                { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
                                { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
                                { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
                                { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
                              ]
                            let tmpincomeAssets=householdIncomeAssets.map((item)=>{
                                //console.log(item.patient_rel)
                                //console.log(relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text));
                                return {                                    
                                        "id": item.id,
                                        "patient_rel": relationStr.filter(mitem=>item.patient_rel===mitem.value).map(mitem=>mitem.text)[0],
                                        "salary":  item.salary.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "allowance":  item.allowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "double_pay":  item.double_pay.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "benefit_in_kind": item.benefit_in_kind.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "pension":  item.pension.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "rental_income":  item.rental_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "sponsor":  item.sponsor.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "other_income": item.other_income.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "isOver65": item.isOver65,
                                        "subTotal": item.subTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "cash": item.cash.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "saving": item.saving.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "investment": item.investment.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "insurance": item.insurance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "property": item.property.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "carpark": item.carpark.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "land": item.land.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "other": item.other.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                        "subTotal2": item.subTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})                                    
                                }});
                            console.log({
                                "hhSize": householdMemberCount,
                                "elderlyCnt": elderlyCount,
                                "householdAssetTotal":householdAssetTotal,
                                "householdIncomeTotal":householdIncomeTotal,
                                "householdIncomeAssets":tmpincomeAssets,
                                "assessmentDate":moment().format("YYYY-MM-DD") ,
                                "lang":lang,
                                "reviewResult":reviewResult,
                                "review_income":review_income,
                                "review_asset":review_asset,
                            });
                            /*open preview*/                   
                            axios({
                                method: 'post',
                                url: APIHost+'/printPreview',
                                data: {
                                    "hhSize": householdMemberCount,
                                    "elderlyCnt": elderlyCount,
                                    "householdAssetTotal":householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                    "householdIncomeTotal":householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}),
                                    "householdIncomeAssets":tmpincomeAssets,
                                    "assessmentDate":moment().format("YYYY-MM-DD") ,
                                    "lang":lang,
                                    "reviewResult":reviewResult,
                                    "review_income":review_income,
                                    "review_asset":review_asset,
                                },
                                responseType: 'blob',
                              }).then((resp)=>{
                                //console.log(resp.data);
                                
                                var blob = new Blob([resp.data],{ type: 'application/pdf' });
                                var url = URL.createObjectURL(blob);
                                window.open(url, '__blank');
                              });                
                            }} >  {props.i18n.t("btnPreview")}</Button>
              
              <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
                  /*confirm clear popup, initial */ 
                  setDiagContent(props.i18n.t("msg_confirm_clear_all"));
                  setDiagTitle(props.i18n.t("tab2_header"));
                  setAllReset(true);
                  setOpen2(true);
              }}>{props.i18n.t("btnClearAll")}</Button>
            </Stack> 
          </Grid>
        </Grid>
       
        
        
        
        <Grid container spacing={2} >
          <Grid item xs={8} >
            <Typography variant="h5"  color="grey.700">  {props.i18n.t("tab2_note")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab2_note_tooltip_title","tab2_note_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
          </Grid>
          
          <Grid item xs={4}  >
            <Stack direction="row" justifyContent="flex-end"> <Typography variant="h5" color="#f44336"> *</Typography><Typography variant="h5" > {props.i18n.t("required_field")}</Typography></Stack>
          </Grid>
        </Grid>
      <Typography variant="h5" paragraph > </Typography>
      </Stack> 
      
        
      
      <Stack direction="row"   justifyContent="flex-start" alignItems="flex-start" >       
        <PatientAccordionHeaderDesktop  householdincomeArry={householdIncomeAssets}  onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
            i18n={props.i18n}/>        
          <Box sx={{overflow: "hidden",overflowX: "scroll",}}>
          <Stack direction="row"   justifyContent="flex-start" alignItems="flex-start" >  
            <PatientAccordionDesktop FamilyIdx={0} householdincomeArry={householdIncomeAssets} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
              onChange={(ev)=>{
                //console.log(ev);
            
              let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,0,ev.item,(ev.item==='isOver65'||ev.item==='patient_rel')?ev.val:convFloat(ev.val));
              //console.log(householdIncomeAssets2); 
              let _subtotal = 
              householdIncomeAssets2[0].salary+
              householdIncomeAssets2[0].allowance+
              householdIncomeAssets2[0].double_pay+
              householdIncomeAssets2[0].benefit_in_kind+
              householdIncomeAssets2[0].pension+
              householdIncomeAssets2[0].rental_income+
              householdIncomeAssets2[0].sponsor+
              householdIncomeAssets2[0].other_income;

              householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,0,"subTotal",_subtotal);
              elderlyCount =0;
              householdIncomeTotal=0;
              householdIncomeAssets2.forEach((record)=>{
                if(record.isOver65 === "true") elderlyCount++;                              
                householdIncomeTotal +=record.subTotal;
              });
              
              //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
              dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));
            }} i18n={props.i18n}/>
            {householdIncomeAssets.map((income)=>{
              //console.log(income);
              if(income.id>0)
                return (
                  <FamilyAccordionDesktop key={"fa"+income.id}  relationStr={relationStr}  FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} 
                onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}}
                onRemove={(id)=>{
                                  let householdIncomeAssets2 = removeHouseholdincomeItem(householdIncomeAssets,id);
                                  elderlyCount =0;
                                  householdIncomeTotal=0;
                                  householdIncomeAssets2.forEach((record)=>{
                                    if(record.isOver65 === "true") elderlyCount++;
                                    householdIncomeTotal +=record.subTotal;
                                  });
                                  householdMemberCount =householdIncomeAssets2.length;
                                  dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount})); 
                                }} 
                onChange={(ev)=>{                      
                                    //console.log(ev);                              
                                    let householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets,income.id,ev.item,(ev.item==='isOver65'||ev.item==='patient_rel')?ev.val:convFloat(ev.val));
                                    //console.log(householdIncomeAssets2); 
                                    let _subtotal = 
                                    householdIncomeAssets2[income.id].salary+
                                    householdIncomeAssets2[income.id].allowance+
                                    householdIncomeAssets2[income.id].double_pay+
                                    householdIncomeAssets2[income.id].benefit_in_kind+
                                    householdIncomeAssets2[income.id].pension+
                                    householdIncomeAssets2[income.id].rental_income+
                                    householdIncomeAssets2[income.id].sponsor+
                                    householdIncomeAssets2[income.id].other_income;
        
                                    householdIncomeAssets2 = updateHouseholdincomeItem(householdIncomeAssets2,income.id,"subTotal",_subtotal);
                                    elderlyCount =0;
                                    householdIncomeTotal=0;
                                    householdIncomeAssets2.forEach((record)=>{
                                      if(record.isOver65 === "true") elderlyCount++;                              
                                      householdIncomeTotal +=record.subTotal;
                                    });
                                    //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
                                    dispatch(setState({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount}));}}  
                                    i18n={props.i18n}/> )
            })}
          </Stack>
          </Box>
      <SubtotalIncomeAccordionDesktop householdincomeArry={householdIncomeAssets} i18n={props.i18n}/>
      </Stack>
      
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
        <TextField value={householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined" sx={{p:1,width:"10rem",input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
      </Stack>
  
      <Typography variant="h6" > {props.i18n.t("input_integer_only")}</Typography>
  
      <Grid container spacing={2} sx={{mt:2}}>
        <Grid item xs={3} >
          <Button variant="outlined" onClick={()=>{props.onChange("info")}} sx={{fontSize: 22}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>        
        <Grid item xs={6} > <Stack direction="row" justifyContent="center" >  <Button  variant="outlined" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button></Stack></Grid>
          
        <Grid item xs={3} ><Stack  direction="row"  justifyContent="flex-end">
          <Button variant="outlined" onClick={()=>{props.onChange("asset");props.onNext();}} sx={{fontSize: 22}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button>
          </Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullWidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalApplicationFormBodyIncome as ECalApplicationFormBodyIncomeDesktop}